import React, { useEffect, useState } from 'react'
import Dropdown from "../../components/Dropdown";
import SemesterService from '../../services/semesterService';
import { SemesterItemVM } from '../../models/SemesterVM';
import styled from 'styled-components';
import AlertDialog from '../../components/AlertDialog';
import { User } from '../../models/User';
import LookupService from '../../services/lookupService';
import { LookupTypeEnum } from '../../enums/lookupType-enum';
import { LookupVM } from '../../models/LookupVM';
import UniService from '../../services/UniService';

const FiltersWrapper = styled.div`
  width:70%;
  background-color: #fff;
  margin-bottom: 1em;
  margin-top: 1em;
  border-radius: 20px;
  border: 1px solid #E2E5ED;
  padding: .5em;
`

const TextLabel = styled.button`
    background-color: transparent;
    color: rgb(35, 142, 107);
    border: none;
    cursor: pointer;
    padding: 1rem;
`;


interface Props {
    buddy: User[];
    students: User[];
    setSemester: (semester: string) => void;
    setStudentType: (semester: string) => void;
    setDepartment: (deparment: string) => void;
    department: string | null;
    setDegreeType: (semester: string) => void;
    semester: string;
    degreeType: string | null;
    studentType: string | null;

    setBuddy: (buddy: User[]) => void;
    setStudents: (student: User[]) => void;
}

export default function MatchmakingFilter({ setSemester, semester, buddy, students, setStudents, setBuddy, setStudentType, setDegreeType, studentType, degreeType, setDepartment, department }: Props) {
    const [semesters, setSemesters] = useState([] as SemesterItemVM[]);
    const [departments, setDepartments] = useState([] as LookupVM[]);
    const [openDialog, setOpenDialog] = useState(false);
    const [tempState, setTempState] = useState("");

    const onChange = (value: string) => {
        if (students.length !== 0 || buddy.length !== 0) {
            handleOpenDialog();
        } else {
            setSemester(value);
        }
    };

    const onChangeStudent = (value: string) => {
        setStudentType(value);
    }

    const onChangeDegree = (value: string) => {
        setDegreeType(value);
    }

    const onChangeDepartment = (value: string) => {
        setDepartment(value);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleAgree = () => {
        setSemester(tempState);
        handleCloseDialog();
        setStudents([]);
        setBuddy([]);
    };

    const handleDisagree = () => {
        handleCloseDialog();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {

                const universityResponse = await new UniService().getInfo();
                const semestersResponse = await new SemesterService().getSemesters(universityResponse.data.id);

                setSemesters(semestersResponse.data.semesters);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDepartments = async () => {
            const response = await new LookupService().getByLookupType(LookupTypeEnum.Departments);
            setDepartments(response.data);
        }

        fetchData();
        fetchDepartments();

    }, []);

    interface Option {
        id: string;
        name: string;
    }

    const dropdownData: Option[] = semesters?.map((semester) => ({
        id: semester.id,
        name: semester.name,
    }));

    const departmentOptions: Option[] = departments?.map((department) => ({
        id: department.id,
        name: department.name,
    }));

    const studentTypeOptions: Option[] = [
        { id: "0", name: "Full time incoming" },
        { id: "1", name: "Erasmus" },
    ];

    const degreeTypeOptions: Option[] = [
        { id: "0", name: "Bachelor" },
        { id: "1", name: "Master" },
    ];

    return (
        <FiltersWrapper>
            <AlertDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onAgree={handleAgree}
                onDisagree={handleDisagree}
            />

            <Dropdown data={dropdownData} onChange={onChange} setValue={setSemester} valuee={semester} setTempState={setTempState} title={"Semester"} />
            {semester !== "" ? (
                <>
                    <Dropdown data={studentTypeOptions} onChange={onChangeStudent} setValue={setStudentType} valuee={studentType} setTempState={() => { }} title={"Type"} />
                    <Dropdown data={degreeTypeOptions} onChange={onChangeDegree} setValue={setDegreeType} valuee={degreeType} setTempState={() => { }} title={"Degree"} />
                    <Dropdown data={departmentOptions} onChange={onChangeDepartment} setValue={setDepartment} valuee={department} setTempState={() => { }} title={"Department"} />
                    <TextLabel>Automatic AI matching</TextLabel >
                </>
            ) : null}

        </FiltersWrapper>
    );
}
