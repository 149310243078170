import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
    open: boolean;
    onClose: () => void;
    onAgree: () => void;
    onDisagree: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, onAgree, onDisagree, }) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle id="alert-dialog-title">{"Change semester?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Selected data will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAgree} autoFocus>
                    Yes
                </Button>
                <Button onClick={onDisagree}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
