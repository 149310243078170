import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import OnboardingService from "../../services/onboardingService";
import OnboardingVM from "../../models/OnboardingVM";
import "./OnboardingProfilePicture.css"

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    onboardingModel?: OnboardingVM;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
}

const OnboardingProfilePicture: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const { t } = useTranslation("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // get the first file selected
        if (file) {
            setSelectedFile(file); // save the selected file to the component state
        }

    };
    const isValid = () => {
        return onboardingModel?.imageToUpload !== undefined || onboardingModel?.image;
    }
    useEffect(() => {
        if (selectedFile && onboardingModel) {
            onboardingModel.imageToUpload = selectedFile;
            setOnboardingModel(onboardingModel);
            new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
        }
    }, [selectedFile])

    useEffect(() => {
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
    }, [])

    return (
        <div className="profile-picture-wrapper">
            <input type="file" id="upload_picture" onChange={handleFileSelect} hidden />
            <p className="bold-font">{t("PROFILE_PICTURE.HEADER")}</p>
            <label htmlFor="upload_picture" className="upload_picture_label">
                {selectedFile
                    ?
                    <img src={URL.createObjectURL(selectedFile)}></img>
                    :
                    onboardingModel?.imageToUpload ?
                        <img src={URL.createObjectURL(onboardingModel.imageToUpload)} />
                        : onboardingModel?.image ? <img src={onboardingModel.image} /> :
                            <img src={require('../../assets/images/add_user-512.webp')} />}
            </label>
        </div>
    )
}

export default OnboardingProfilePicture