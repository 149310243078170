type LocalStorageService = {
    getItem: (key: string) => string | null;
    setItem: (key: string, value: string) => void;
    clear: () => void;
  };
  
  export const localStorageService: LocalStorageService = {
    getItem: (key: string) => {
      return localStorage.getItem(key);
    },
    setItem: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
    clear: () => {
      localStorage.clear();
    }
  };
  