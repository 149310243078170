import { Tab, Tabs } from "@mui/material";
import React from "react";
import "../StudentRequest/StudentRequest.css";
import "./UniversitySettings.css";
import Box from "@mui/material/Box";
import Semesters from "../Semesters/Semesters";
import UniversityInfo from "./UniversityInfo";
import useAlert from "../Alert/useAlert";
interface LinkTabProps {
  label?: string;
  href?: string;
}
function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      className="university-setting-tab"
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
      sx={{ textTransform: "none", color: "#001737" }}
    />
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function University() {
  const { showAlert, AlertComponent } = useAlert();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="student-request-container">
      {AlertComponent}
      <div className="student-request-title">
        <h3>University Settings</h3>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        className="university-setting-tabs"
      >
        <LinkTab label="Info" {...a11yProps(0)} />
        <LinkTab label="Semesters" {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UniversityInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Semesters showAlert={showAlert} />
      </TabPanel>
    </div>
  );
}
