import ApiService, { ApiResponse } from "./ApiService";
import { SemesterRequestModel } from "../models/SemesterRequestModel";
import axios from "axios";
import { SemesterVM } from "../models/SemesterVM";
class SemesterService extends ApiService {
  constructor() {
    super("semester");
  }

  public async getSemesters(universityId: string) {
    return this.get<SemesterVM>(`/${universityId}`);
  }

  public async createSemester(
    semester: SemesterRequestModel
  ): Promise<ApiResponse<any>> {
    return this.post<SemesterRequestModel, any>("/create", semester);
  }
  public async deleteSemester(semesterId: string) {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}semester?semesterId=${semesterId}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { response, message: response.statusText };
  }
}
export default SemesterService;
