export interface ApiResponse<T> {
  error_code: number;
  message: string;
  data: T;
  validation_errors: string[];
  custom_message: string;
}

class ApiService {
  protected readonly baseURL = process.env.REACT_APP_API_URL;
  protected readonly headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  constructor(baseURL: string) {
    this.baseURL += baseURL;
  }

  protected async fetch<T>(
    url: string,
    options: RequestInit
  ): Promise<ApiResponse<T>> {
    const response = await fetch(`${this.baseURL}${url}`, options);
    const data = await response.json();

    return {
      data: data.data,
      message: response.statusText,
      error_code: data.error_code,
      validation_errors: data.validation_errors,
      custom_message: data.message,
    };
  }

  protected async get<T>(url: string): Promise<ApiResponse<T>> {
    return this.fetch<T>(url, { method: "GET", credentials: "include" });
  }
  protected async post<R, T>(url: string, data: R): Promise<ApiResponse<T>> {
    return this.fetch<T>(url, {
      method: "POST",
      headers: this.headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
  }

  protected async put<T>(url: string, data: T): Promise<ApiResponse<T>> {
    return this.fetch<T>(url, {
      method: "PUT",
      headers: this.headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
  }

  protected async delete<T>(url: string): Promise<ApiResponse<T>> {
    return this.fetch<T>(url, {
      method: "DELETE",
      credentials: "include",
      headers: this.headers,
    });
  }

  protected async patch<R, T>(url: string, data: R): Promise<ApiResponse<T>> {
    return this.fetch<T>(url, {
      method: "PATCH",
      headers: this.headers,
      credentials: "include",
      body: JSON.stringify(data),
    });
  }
  
  protected async upload<T>(url: string, data: T): Promise<ApiResponse<T>> {
    const formData = new FormData();
    const file = data as File;
    formData.append('formFile', file);
    return this.fetch(url, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
  }

}

export default ApiService;
