import { Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, ThemeProvider, createTheme } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenderEnum } from '../../enums/gender-enum';
import OnboardingService from '../../services/onboardingService';
import OnboardingVM from '../../models/OnboardingVM';
import './OnboardingPersonal.css'

const ages: number[] = Array.from({ length: 20 }, (_, i) => i + 18);

const theme = createTheme({
    palette: {
        primary: {
            main: "#33CC99", //this overide blue color with base green color of our app
            contrastText: "#fff"
        },
    },
    typography: {
        "fontFamily": "Montserrat Regular",
        "fontSize": 11
    }
});

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
    onboardingModel?: OnboardingVM;
}

const OnboardingPersonal: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const { t } = useTranslation("");
    const [gender, setGender] = useState<GenderEnum>(GenderEnum.None)
    const [ageId, setAgeId] = useState<number>(0);
    const [phone, setPhone] = useState("");
    const [localOnboardingModel] = useState(onboardingModel);
    const isValid = () => {
        return gender !== GenderEnum.None && ageId !== 0 && phone;
    }
    const handleAgeIdChange = (event: SelectChangeEvent<number>) => {
        const value = event.target.value as number;
        setAgeId(value);
    }
    const updateOnboardingModel = () => {
        if (localOnboardingModel) {
            if (gender !== GenderEnum.None)
                localOnboardingModel.gender = gender;
            if (ageId)
                localOnboardingModel.age = ageId;
            if (phone)
                localOnboardingModel.phone_number = phone;
            setOnboardingModel(localOnboardingModel);
        }
    }

    useEffect(() => {
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
        updateOnboardingModel();
    }, [gender, ageId, phone])

    useEffect(() => {
        if (localOnboardingModel && localOnboardingModel.gender !== null)
            setGender(localOnboardingModel!.gender);
        if (localOnboardingModel && localOnboardingModel.age !== null)
            setAgeId(localOnboardingModel!.age)
        if (localOnboardingModel && localOnboardingModel.phone_number)
            setPhone(localOnboardingModel.phone_number)
    }, [])

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        
        // Remove all non-numeric characters
        const numericValue = inputValue.replace(/\D/g, '');
        
        // Add '+' sign before the numeric value
        const formattedValue = `+${numericValue}`;
        
        setPhone(formattedValue);
    };

    return (
        <div>
            <div className="personal-wrapper">
                <div className='choose-gender-wrapper'>
                    <p className="bold-font">{t("ONBOARDING_PERSONAL.GENDER")}</p>
                    <div className='choose-gender'>
                        <div className={gender === GenderEnum.Male ? "gender-selected" : "gender-unselected"}>
                            <img
                                src={require("../../assets/images/male-onboarding-pic.png")}
                                onClick={() => setGender(GenderEnum.Male)}
                                alt=""
                            />
                        </div>
                        <div className={gender === GenderEnum.Female ? "gender-selected" : "gender-unselected"}>
                            <img
                                src={require("../../assets/images/female-onboarding-pic.png")}
                                alt=""
                                onClick={() => setGender(GenderEnum.Female)}
                            />
                        </div>
                        <div
                            className={gender === GenderEnum.Diverse ? "gender-selected" : "gender-unselected"}
                            onClick={() => setGender(GenderEnum.Diverse)}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <h4 style={{ color: "gray", margin: "0" }}>Not specified</h4>
                        </div>
                    </div>
                </div>
                <div className="choose-age-wrapper">
                    <div className="choose-age">
                        <p className="bold-font">{t("ONBOARDING_PERSONAL.AGE")}</p>
                        <ThemeProvider theme={theme}>
                            <Container sx={{ width: '150px' }} component="main" maxWidth="xs">
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Age"
                                        name='ageId'
                                        value={ageId}
                                        style={{ maxHeight: 100, overflowY: 'auto' }}
                                        displayEmpty
                                        onChange={handleAgeIdChange}>
                                        {
                                            ages.map((element) => {
                                                return <MenuItem key={element} value={element}>{element}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Container>
                            <div className='personal-phone-number-wrapper'>
                                <div className="personal-phone-number">
                                    <p className="bold-font">Enter your phone number</p>
                                    <Container sx={{ width: '250px' }} component="main" maxWidth="xs">
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <TextField
                                                fullWidth
                                                label="Phone number"
                                                onChange={handlePhoneNumberChange}
                                                value={phone}
                                            />
                                        </FormControl>
                                    </Container>
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingPersonal