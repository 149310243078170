import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import { AuthService } from '../../services/authService';
import { useNavigate } from 'react-router';

interface Props {
    displayName: string,
    userProfilePicture: string
}

const AccountMenu: React.FC<Props> = ({ displayName, userProfilePicture }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const navigate = useNavigate();
    const logOut = async () => {
        await new AuthService().Logout();
        navigate("/login");
    }
    const navigateToUserProfile = () => {
        navigate("/user-profile");
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {
                        userProfilePicture ? <Avatar src={userProfilePicture} variant='square'></Avatar> :
                            displayName !== '' ?
                                <Avatar {...stringAvatar(displayName)} variant='square' sx={{ width: 36, height: 36, fontSize: "1.1rem !important", fontFamily: "Montserrat Medium", backgroundColor: "#33CC99" }}></Avatar>
                                : null
                    }
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => { handleClose(); navigateToUserProfile() }}>
                    <Avatar /> Profile
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); logOut() }}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export default AccountMenu;