import { useState } from "react";
import SearchUsersWrapper from "./SearchUsersWrapper";
import styled from "styled-components";
import ManualMatchmakingCard from "./ManualMatchmakingCard";
import { User } from "../../models/User";

const Wrapper = styled.div`
    height: 100%;
    display:flex;
    justify-content:space-between;
    background-color: #F8F8FB;
`

function Matchmaking() {
  const [buddy, setBuddy] = useState<User[]>([]);
  const [students, setStudents] = useState<User[]>([]);
  const [semester, setSemester] = useState<string>("");
  const [degreeType, setDegreeType] = useState<string | null>(null);
  const [studentType, setStudentType] = useState<string | null>(null);
  const [department, setDepartment] = useState<string | null>(null);
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  return (
    <Wrapper>
      <SearchUsersWrapper
        multiSelect={true}
        searchTitle="Buddies"
        semester={semester}
        studentType={studentType}
        degreeType={degreeType}
        department={department}
        role={0}
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
        usersList={buddy}
        setUsersList={setBuddy}
      />
      <ManualMatchmakingCard buddy={buddy} students={students} setSemester={setSemester}
        semester={semester} setStudents={setStudents} setBuddy={setBuddy} studentType={studentType}
        degreeType={degreeType} setDegreeType={setDegreeType} setStudentType={setStudentType} shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch} department={department} setDepartment={setDepartment} />
      <SearchUsersWrapper
        multiSelect={true}
        searchTitle="Students"
        department={department}
        semester={semester}
        studentType={studentType}
        degreeType={degreeType}
        role={1}
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
        usersList={students}
        setUsersList={setStudents}
      />
    </Wrapper>
  );
}

export default Matchmaking;