import ApiService, { ApiResponse } from "./ApiService";
import { LookupTypeEnum } from "../enums/lookupType-enum";
import { LookupVM } from "../models/LookupVM";

class LookupService extends ApiService{
    constructor(){
        super("/lookup")
    }

    public async getByLookupType(lookupType: LookupTypeEnum): Promise<ApiResponse<LookupVM[]>>{
        return this.get<LookupVM[]>(`/${lookupType}`);
    }
}

export default LookupService