import * as React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import { NavbarEnum } from "../../enums/navbar-enum";
import UniversityInfo from "./UniversityInfo";
import "./UniversitySettings.css";
export default function UniversityInfoWithNav() {
  return (
    <div className="uni-info">
      <NavigationBar navbarEnum={NavbarEnum.University}></NavigationBar>
      <UniversityInfo />
    </div>
  );
}
