import { useState } from 'react';
import styled from 'styled-components';
import { User } from '../../models/User'
import { forwardRef } from 'react';
import { Tooltip } from '@mui/material';

const CardWrapper = styled.div`
transition: opacity 0.9s ease-in-out;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: .7em 1.5em;
  cursor: pointer;

  &:hover, &.checked {
    background-color: var(--background-hover);
  }
`;

const UserDataWrapper = styled.div`
  width: 100%;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: .8em;
  object-fit: cover;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 1.13em;
  background-color: #F1F2F6;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
`;

const FullName = styled(EllipsisText)`
  color: #001737;
  font-size: 0.875em;
`;

const StudyField = styled(EllipsisText)`
  font-size: 0.813em;
  color: #848484;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
`;

type UserCardProps = {
  checked: boolean;
  multiSelectMode: boolean;
  user: User;
  usersList: User[];
  setUsersList: (student: User[]) => void;
};

const UserCard = forwardRef<HTMLDivElement, UserCardProps>(({ checked, multiSelectMode, setUsersList, usersList, user }, ref) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const fallbackImageUrl = 'https://ui-avatars.com/api/?rounded=true';
  const fullName = `${user.first_name} ${user.last_name}`;

  const getInfo = (user: User) => {
    var languages = user.languages ? user.languages.join(',') : [];
    const message = `Age: ${user.age}\nGender: ${user.gender}\nLanguages: ${languages}`;
    return message;
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = () => {
    if (multiSelectMode) {
      const updatedUsers = checked
        ? usersList.filter((userObj) => userObj.id !== user.id)
        : [...usersList, user];

      //setIsChecked(!isChecked);
      setUsersList(updatedUsers);
    } else {
      checked ? setUsersList([]) : setUsersList([user]);
    }
  };
  return (
    <Tooltip title={
      <div style={{ whiteSpace: "pre-line" }}>
        {getInfo(user)}
      </div>
    } placement='top'>
      <CardWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
        {multiSelectMode && (checked || isHovered) ? (
          <CheckboxWrapper>
            <input type="checkbox" checked={checked} onChange={handleChange} />
          </CheckboxWrapper>
        ) : checked || isHovered ? (
          <CheckboxWrapper>
            <input type="checkbox" checked={checked} readOnly />
          </CheckboxWrapper>
        ) : (
          <ProfileImage
            src={user.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(fullName)}`}
            alt=""
          />
        )}
        <UserDataWrapper>
          <FullName>{`${user.first_name} ${user.last_name}`}</FullName>
          <StudyField>{`${user.study_type}`}</StudyField>
        </UserDataWrapper>
      </CardWrapper >
    </Tooltip>
  );
});

export default UserCard;
