import React, { useState } from 'react'
import styled from 'styled-components';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MatchmakingFilter from "./MatchmakingFilter";
import { Divider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import MatchmakingService from '../../services/MatchmakingService';
import { AxiosResponse } from 'axios';

const MainWrapper = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 2em;
    display:flex;
    flex-direction: column;
    align-items: center;
    width:70%;
    border: 1px solid #E2E5ED;
`

const UserCardsWrapper = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    padding-bottom: 3em;
`
const UserCard = styled.div`
    padding: 0 2em 0 2em;
    width:250px;
    display: flex;
    flex-direction:column;
    align-items:center;
`

const ProfileImage = styled.img`
    border-radius: 50%;
    width: ${props => props.width};
    height: ${props => props.height};
    object-fit: cover;
    margin-top:10px;
    margin-bottom:10px;
`
const ProfileImageWrapper = styled.div`
    position: relative;
`;

const Font = styled.div`
    font-size:14px;
    color: #001737;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align:center;
    width: 80%;
    overflow:hidden;
`

const AcceptButton = styled.button`
  padding: 0.5em 1em;
  width: 135px;
  background-color: #33CC99;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #E2E5ED;

  &:hover {
    background-color: #2EBC8A;
  }

  &:active {
    background-color: #24996C;
  }

  &:disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
`;

const DeclineButton = styled.button`
  font-weight: bold;
  padding: 0.5em 1em;
  width: 135px;
  border: 1px solid #E2E5ED;
  outline: none;
  background-color: #fff;
  margin-right: 1em;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #001737;

  &:hover {
    background-color: #E2E5ED;
    color: #001737;
  }

  &:active {
    background-color: #001737;
    color: #fff;
  }
`;


type User = {
    id: string;
    first_name: string;
    last_name: string;
    image: string;
    study_type: string;
};


const Title = styled.div`
    color: #AAAAAA;
    margin-bottom: 10px;
    font-size:12px;
`

const ScrollWrapper = styled.div`
    display: flex;
    overflow-y: scroll;
    height:100px;
    align-items:center;
    flex-direction:column;
    ::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
      }
    
      ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
      }
    
      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
   
    `;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5em;
  height: 5em;
  margin-top:10px;
  margin-bottom:10px;
  border-radius: 50%;
  background-color:lightgray;
`;

const Wrapper = styled.div`
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
`

const InfoMessage = styled.div`
    padding: .3em;
    color: #AAAAAA;
`

const Item = styled.div`
    margin: 0em .3em;
`

interface Props {
    buddy: User[];
    students: User[];
    setSemester: (semester: string) => void;
    semester: string;
    degreeType: string | null;
    studentType: string | null;
    setBuddy: (buddy: User[]) => void;
    setStudents: (student: User[]) => void;
    setStudentType: (semester: string) => void;
    setDegreeType: (semester: string) => void;
    shouldRefetch: boolean;
    setShouldRefetch: (shouldRefetch: boolean) => void;
    setDepartment: (deparment: string) => void;
    department: string | null;
}

function ManualMatchmakingCard({ buddy, students, setSemester, semester, setStudents, setBuddy, setStudentType, setDegreeType, studentType, degreeType, shouldRefetch, setShouldRefetch, department, setDepartment }: Props) {
    const [visibleIndexStudent, setVisibleIndexStudent] = useState(0);
    const [visibleIndexBuddy, setVisibleIndexBuddy] = useState(0);
    const buddyNames = buddy.map(buddy => `${buddy.first_name} ${buddy.last_name}`);
    const studentNames = students.map(student => `${student.first_name} ${student.last_name}`);
    const fallbackImageUrl = 'https://ui-avatars.com/api/?rounded=true';
    const [isLoading, setIsLoading] = useState(false);
    const buddyIds = buddy.map(buddy => buddy.id);
    const studentIds = students.map(student => student.id);

    const handleScrollBuddy = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        const itemHeight = 90; // adjust this value to match the height of the Item component
        const newIndex = Math.round(scrollTop / itemHeight);
        setVisibleIndexBuddy(newIndex);
    };

    const handleScrollStudent = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        const itemHeight = 90; // adjust this value to match the height of the Item component
        const newIndex = Math.round(scrollTop / itemHeight);
        setVisibleIndexStudent(newIndex);
    };


    const ConfirmMatch = () => {

        if (window.confirm("Match selected Users?")) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
            MatchmakingService.matchUsers({
                buddy_ids: buddyIds,
                student_ids: studentIds
            })
                .then((response: AxiosResponse<any>) => {
                    toast.success('Matching successful!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setStudents([]);
                    setBuddy([]);
                    setShouldRefetch(true);
                    setIsLoading(false);
                })
        }
    }

    const DeclineMatch = () => {

        if (window.confirm("Selected Users will be removed. Are you sure?")) {
            setStudents([]);
            setBuddy([]);
            toast.warning('Matching canceled!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <Wrapper>
            <ToastContainer />
            <MatchmakingFilter setSemester={setSemester} semester={semester} buddy={buddy} students={students} setStudents={setStudents} setBuddy={setBuddy} degreeType={degreeType} setStudentType={setStudentType} setDegreeType={setDegreeType} studentType={studentType} department={department} setDepartment={setDepartment} />
            <MainWrapper>
                <UserCardsWrapper>
                    <UserCard>
                        <Title>BUDDY</Title>
                        <ScrollWrapper onScroll={handleScrollBuddy}>
                            {buddy.length > 0 ? (
                                buddy.map((buddy) => (
                                    <ProfileImageWrapper key={buddy.id}>
                                        <ProfileImage
                                            height={'5rem'}
                                            width={'5rem'}
                                            src={buddy.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(buddy.first_name + ' ' + buddy.last_name)}&font-size=0.33&background=001737&color=fff`}
                                            alt=""
                                        />
                                    </ProfileImageWrapper>
                                ))
                            ) : (
                                <StyledIconContainer>
                                    <PersonAddIcon fontSize='medium' color='action' />
                                </StyledIconContainer>
                            )}
                        </ScrollWrapper>
                        {
                            buddy.length === 0 ? <Font>Select buddy</Font> : <Font>{buddyNames[visibleIndexBuddy]}</Font>
                        }
                    </UserCard>
                    <Divider orientation="vertical" />
                    <UserCard>
                        <Title>STUDENT ({students.length})</Title>
                        <ScrollWrapper onScroll={handleScrollStudent}>
                            {students.length > 0 ? (
                                students.map((student) => (
                                    <Item key={student.id}>
                                        <ProfileImage
                                            src={student.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(student.first_name + ' ' + student.last_name)}&font-size=0.33&background=33CC99&color=fff`}
                                            height={'5rem'}
                                            width={'5rem'}
                                        />
                                    </Item>
                                ))
                            ) : (
                                <StyledIconContainer>
                                    <GroupAddIcon fontSize='medium' color='action' />
                                </StyledIconContainer>
                            )}
                        </ScrollWrapper>
                        {
                            students.length === 0 ? <Font>Select student</Font> : <Font>{studentNames[visibleIndexStudent]}</Font>
                        }
                    </UserCard>
                </UserCardsWrapper>
                {students.length === 0 || buddy.length === 0 ? (
                    <div>
                        <InfoMessage>Select minimum one student and buddy.</InfoMessage>
                    </div>
                ) : (
                    <div>
                        <DeclineButton onClick={DeclineMatch}>Decline</DeclineButton>
                        <AcceptButton disabled={isLoading} onClick={ConfirmMatch}>  {isLoading ? < CircularProgress color="inherit" size={13} />

                            : 'Match'} </AcceptButton>
                    </div>
                )
                }

            </MainWrapper >
        </Wrapper>
    )
}

export default ManualMatchmakingCard