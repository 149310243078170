import React, { useState } from "react";
import { Alert, AlertColor, createTheme, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
export interface AlertProps {
  message: string;
  severity: AlertColor;
}
const theme = createTheme({
  palette: {
    success: {
      main: "#7be382",
    },
    error: {
      main: "#ffbaba",
    },
  },
});
const useAlert = () => {
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const showAlert = (message: string, severity: AlertColor) => {
    setAlert({ message, severity });
  };

  const handleClose = () => {
    setAlert(null);
  };

  return {
    alert,
    showAlert,
    AlertComponent: alert ? (
      <ThemeProvider theme={theme}>
        <Stack
          sx={{ width: "20rem", float: "right!important" }}
          className="alert"
        >
          <Alert
            severity={alert.severity}
            onClose={handleClose}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Stack>
      </ThemeProvider>
    ) : null,
  };
};

export default useAlert;