import { Container, ThemeProvider, CssBaseline, Box, Grid, TextField, createTheme } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import OnboardingVM from "../../models/OnboardingVM";
import OnboardingService from "../../services/onboardingService";
import './OnboardingMotivation.css'

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
    onboardingModel?: OnboardingVM;
}


const OnboardingMotivation: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const [involvedReason, setInvolvedReason] = useState("");
    const [benefits, setBenefits] = useState("");
    const [expectations, setExpectations] = useState("");
    const [localOnboardingModel] = useState(onboardingModel);
    const defaultTheme = createTheme({
        palette: {
            primary: {
                main: "#33CC99", //this overide blue color with base green color of our app
                contrastText: "#fff"
            },
        },
        typography: {
            "fontFamily": "Montserrat Regular",
            "fontSize": 14
        }
    });
    const updateOnboardingModel = () => {
        if (localOnboardingModel) {
            if (involvedReason)
                localOnboardingModel.involved_reason = involvedReason;
            if (benefits)
                localOnboardingModel.benefits = benefits;
            if (expectations)
                localOnboardingModel.expectations = expectations;
            setOnboardingModel(localOnboardingModel);
        }
    }
    const isValid = () => {
        return involvedReason && benefits && expectations;
    };
    useEffect(() => {
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
        updateOnboardingModel();
    }, [involvedReason, benefits, expectations]);
    useEffect(() => {
        if (localOnboardingModel && localOnboardingModel.involved_reason)
            setInvolvedReason(localOnboardingModel!.involved_reason);
        if (localOnboardingModel && localOnboardingModel.benefits)
            setBenefits(localOnboardingModel.benefits)
        if (localOnboardingModel && localOnboardingModel.expectations)
            setExpectations(localOnboardingModel.expectations)
    }, []);
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <p className="bold-font onb-motivation-p-first style-motivation-p-text">Why do you want to get involved with our program?</p>
                    <p className="onb-motivation-disclaimer-p">Answer can be provided also in German</p>
                    <Box component="form" noValidate sx={{ mt: 0, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-static"
                                    multiline
                                    rows={2.5}
                                    fullWidth
                                    variant="filled"
                                    value={involvedReason}
                                    onChange={e => setInvolvedReason(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <p className="bold-font onb-motivation-p-second style-motivation-p-text">What makes you a good Buddy and what can your International expect from you?</p>
                    <p className="onb-motivation-disclaimer-p">Answer can be provided also in German</p>
                    <Box component="form" noValidate sx={{ mt: 0, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-static"
                                    multiline
                                    rows={2.5}
                                    fullWidth
                                    variant="filled"
                                    value={benefits}
                                    onChange={e => setBenefits(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <p className="bold-font onb-motivation-p-second style-motivation-p-text">What are your expectations of the Buddy Program?</p>
                    <p className="onb-motivation-disclaimer-p">Answer can be provided also in German</p>
                    <Box component="form" noValidate sx={{ mt: 0, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-static"
                                    multiline
                                    rows={2.5}
                                    fullWidth
                                    variant="filled"
                                    value={expectations}
                                    onChange={e => setExpectations(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

export default OnboardingMotivation