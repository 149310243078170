import { Container, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme, ThemeProvider, createTheme } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './OnboardingSubPersonalSecond.css'
import { LookupVM } from '../../../models/LookupVM';
import LookupService from '../../../services/lookupService';
import { LookupTypeEnum } from '../../../enums/lookupType-enum';
import OnboardingService from '../../../services/onboardingService';
import OnboardingVM from '../../../models/OnboardingVM';
import OnboardingLookupVM from '../../../models/OnboardingLookupVM';
import { localStorageService } from '../../../services/localStorageService';

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
    onboardingModel?: OnboardingVM
}

const OnboardingSubPersonalSecond: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const [languages, setLanguages] = useState([] as LookupVM[])
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    const [localOnboardingModel] = useState(onboardingModel);
    const { t } = useTranslation("");
    const theme = createTheme({
        palette: {
            primary: {
                main: "#33CC99", //this overide blue color with base green color of our app
                contrastText: "#fff"
            },
        },
        typography: {
            "fontFamily": "Montserrat Regular",
            "fontSize": 11
        }
    });
    const isValid = () => {
        return selectedLanguages.length > 0;
    }
    const updateOnboardingModel = () => {
        if (localOnboardingModel && selectedLanguages.length > 0) {
            const languages: OnboardingLookupVM[] = selectedLanguages.map((x) => { return { lookup_id: x } })
            localOnboardingModel.languages = languages;
            setOnboardingModel(localOnboardingModel);
        }
    }

    async function getLanguages() {
        const key = 'languages';
        const cachedData = localStorageService.getItem(key);
        if (cachedData) {
            setLanguages(JSON.parse(cachedData))
        }
        else {
            const response = await new LookupService().getByLookupType(LookupTypeEnum.Languages);
            const data = response.data;
            localStorageService.setItem(key, JSON.stringify(data));
            setLanguages(data);
        }
    }

    useEffect(() => {
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
        updateOnboardingModel();
    }, [selectedLanguages])

    useEffect(() => {
        getLanguages();

        if (localOnboardingModel?.languages) {
            const languageIds = localOnboardingModel.languages.map((x) => x.lookup_id);
            setSelectedLanguages(languageIds);
        }
    }, [])

    const handleChange = (event: SelectChangeEvent<typeof selectedLanguages>) => {
        const { target: { value }, } = event;
        setSelectedLanguages(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <div>
            <div className="personal-wrapper">
                <div className="choose-language-wrapper">
                    <div className="choose-language">
                        <p className="bold-font">{t("ONBOARDING_PERSONAL.LANGUAGES")}</p>
                        <Container sx={{ width: '250px' }} component="main" maxWidth="xs">
                            <ThemeProvider theme={theme}>
                                <FormControl sx={{ width: "200px" }}>
                                    <InputLabel id="demo-multiple-name-label">Languages</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        multiple
                                        value={selectedLanguages}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Languages" />}
                                        name='languageId'
                                    >
                                        {languages.map((element) => (
                                            <MenuItem
                                                key={element.id}
                                                value={element.id}
                                                style={getStyles(element.name, selectedLanguages, theme)}
                                            >
                                                {element.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingSubPersonalSecond