import { InterestOptions } from "../../models/InterestOptionsVM";
import { useTranslation } from "react-i18next";
import ChoiceOptionColumn from "../ChoiceOptionColumn/ChoiceOptionColumn";
import './MultipleChoice.css'

interface Props {
    options: InterestOptions[]
    header: string
    setOptions: any;
}

const MultipleChoice: React.FC<Props> = ({ options, header, setOptions }) => {
    const { t } = useTranslation("");
    const handleAnswerClick = (index: number) => {
        const newSelectedAnswers = [...options];
        newSelectedAnswers[index].selected = !newSelectedAnswers[index].selected;
        setOptions(newSelectedAnswers);
    };
    const numAnswers = options.length;
    const numAnswersPerColumn = Math.ceil(numAnswers / 2);
    const leftColumnAnswers = options.slice(0, numAnswersPerColumn);
    const rightColumnAnswers = options.slice(numAnswersPerColumn);

    return (
        <div className="multiple-choice-wrapper">
            <p className="bold-font">
                {header}
            </p>
            <div className="multiple-choice-header semiBold-font">
                {t("MULTIPLE_CHOICE.HEADER")}
            </div>
            <div className="multiple-choice-container">
                <ChoiceOptionColumn options={leftColumnAnswers} handleAnswerClick={handleAnswerClick}></ChoiceOptionColumn>
                <ChoiceOptionColumn options={rightColumnAnswers} handleAnswerClick={handleAnswerClick}  numAnswersPerColumn={numAnswersPerColumn}></ChoiceOptionColumn>
            </div>
        </div>
    )
}

export default MultipleChoice