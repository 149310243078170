import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { SemesterRequestModel } from "../../models/SemesterRequestModel";
import SemesterService from "../../services/semesterService";
export interface ModalProps {
  open: any;
  handleClose: any;
  fetch: any;
  showAlert: any;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#33CC99",
      contrastText: "#fff",
    },
  },
});
export default function SemesterModal(props: ModalProps) {
  const { open, handleClose, fetch, showAlert } = props;

  const [semesterValue, setSemesterValue] = useState("Winter Semester");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { t } = useTranslation();

  const createSemester = async () => {
    try {
      handleClose();
      const request: SemesterRequestModel = {
        name: semesterValue,
        start_date: startDate,
        end_date: endDate,
      };
      const response = await new SemesterService().createSemester(request);
      if (response.message === "OK") {
        await fetch();
        showAlert(t("SEMESTER.SUCCESS_ADD"), "success");
      } else {
        showAlert(t("SEMESTER.ERROR_ADD"), "error");
      }
    } catch (err) {
    } finally {
      clearValues();
    }
  };

  const clearValues = () => {
    setEndDate(null);
    setStartDate(null);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle>{t("SEMESTER.ADD_TITLE")}</DialogTitle>
      <Divider />
      <DialogContent sx={{ marginTop: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FormControl>
              <InputLabel id="demo-controlled-open-select-label">
                Name
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={semesterValue}
                onChange={(e) => setSemesterValue(e.target.value)}
                sx={{ width: 200 }}
              >
                <MenuItem value={"Winter Semester"}>Winter Semester</MenuItem>
                <MenuItem value={"Summer Semester"}>Summer Semester</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  onChange={(newValue) => setStartDate(newValue)}
                  value={startDate}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date"
                  onChange={(newValue) => setEndDate(newValue)}
                  value={endDate}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={theme}>
          <Button
            variant="contained"
            sx={{ textTransform: "none", marginRight: 2, marginBottom: 2 }}
            onClick={createSemester}
          >
            Save
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
}
