import { Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, ThemeProvider, createTheme } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { localStorageService } from '../../../services/localStorageService';
import { useTranslation } from 'react-i18next';
import { LookupTypeEnum } from '../../../enums/lookupType-enum';
import { LookupVM } from '../../../models/LookupVM';
import OnboardingService from '../../../services/onboardingService';
import LookupService from '../../../services/lookupService';
import OnboardingVM from '../../../models/OnboardingVM';
import OnboardingLookupVM from '../../../models/OnboardingLookupVM';
import './OnboardingSubPersonalFirst.css'

const theme = createTheme({
    palette: {
        primary: {
            main: "#33CC99", //this overide blue color with base green color of our app
            contrastText: "#fff"
        },
    },
    typography: {
        "fontFamily": "Montserrat Regular",
        "fontSize": 11
    }
});

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
    onboardingModel?: OnboardingVM
}

const OnboardingSubPersonalFirst: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const [countries, setCountries] = useState([] as LookupVM[]);
    const [countryId, setCountryId] = useState("");
    const [localOnboardingModel] = useState(onboardingModel);
    const { t } = useTranslation("");
    const handleCountryIdChange = (event: SelectChangeEvent<string>) => {
        const { value } = event.target;
        setCountryId(value);
    }
    const isValid = () => {
        return countryId !== "";
    }
    const updateOnboardingModel = () => {
        if (localOnboardingModel && countryId) {
            const countryLookup: OnboardingLookupVM = {lookup_id: countryId};
            localOnboardingModel.country = countryLookup;
            setOnboardingModel(localOnboardingModel);
        }
    }
    async function setCountriesState() {
        const key = "countries";
        const cachedData = localStorageService.getItem(key);
        if (cachedData) {
            setCountries(JSON.parse(cachedData))
        } else {
            const response = await new LookupService().getByLookupType(LookupTypeEnum.Countries);
            const data = response.data;
            localStorageService.setItem(key, JSON.stringify(data));
            setCountries(data);
        }
    }

    useEffect(() => {
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
        updateOnboardingModel();
    }, [countryId])

    useEffect(() => {
        setCountriesState();
        if (localOnboardingModel?.country)
            setCountryId(localOnboardingModel.country.lookup_id)
    }, [])

    return (
        <div>
            <div className="personal-wrapper">
                <div className="choose-country-wrapper">
                    <div className="choose-country">
                        <p className="bold-font m-0">{t("ONBOARDING_PERSONAL.COUNTRY")}</p>
                        <Container sx={{ width: '250px' }} component="main" maxWidth="xs">
                            <ThemeProvider theme={theme}>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Country"
                                        name='countryId'
                                        value={countryId}
                                        onChange={handleCountryIdChange}>
                                        {
                                            countries.map((element) => {
                                                return <MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardingSubPersonalFirst