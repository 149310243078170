import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./BaseTable.css";

interface BaseListProps {
  columns: GridColDef[];
  rows: any[];
  totalRowCount: number;
  setPaginationModel: any;
  paginationModel: any;
  loading: any;
}

export default function BaseTable({
  columns,
  rows,
  totalRowCount,
  setPaginationModel,
  paginationModel,
  loading,
}: BaseListProps) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize || rows.length,
          },
        },
      }}
      disableColumnSelector
      className="no-scroll"
      paginationModel={paginationModel}
      onPaginationModelChange={(value) => setPaginationModel(value)}
      rowCount={totalRowCount}
      disableColumnMenu={true}
      rowSelection={false}
      loading={loading}
      paginationMode="server"
    />
  );
}
