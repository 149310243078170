import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UniversityOnboardingVM } from "../../models/UniversityOnboardingVM";
import OnboardingService from "../../services/onboardingService";
import "./UniOnboardingPicture.css"

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    onboardingModel?: UniversityOnboardingVM;
    setOnboardingModel: Dispatch<SetStateAction<UniversityOnboardingVM>>;
}

const UniOnboardingProfilePicture: React.FC<Props> = ({ onboardingModel, setOnboardingModel, setDisableContinueButton }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // get the first file selected
        if (file) {
            setSelectedFile(file); // save the selected file to the component state
        }
    };
    const isValid = () => {
        return onboardingModel?.image !== undefined;
    }

    useEffect(() => {
        if (onboardingModel && selectedFile) {
            onboardingModel.image = selectedFile;
            setOnboardingModel(onboardingModel);
        }
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
    }, [selectedFile])

    return (
        <div className="uni-profile-picture-wrapper">
            <input type="file" id="upload_picture" onChange={handleFileSelect} hidden />
            <p className="bold-font uni-onboarding-profile-p">Please add university picture</p>
            <label htmlFor="upload_picture" className="uni-upload_picture_label">
            {selectedFile
                    ?
                    <img src={URL.createObjectURL(selectedFile)}></img>
                    :
                    onboardingModel?.image ?
                        <img src={URL.createObjectURL(onboardingModel.image)} />
                        :
                        <img src={require('../../assets/images/uni.png')} />}
            </label>
        </div>
    )
}

export default UniOnboardingProfilePicture