import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UserCardsList from './UserCardsList';
import _ from 'lodash';
import { User } from '../../models/User';


const SearchWrapper = styled.div`
  background-color: #fff;
  width:20%;
  border-right: 1px solid #E2E5ED;
  height: 100%;
`

const SearchInput = styled.input`
  background-color: #F8F8FB;
  border-radius: 8px;
  padding: 0.6em 0.5em;
  border:none;
  width: 100%;
  font-size: 0.813rem;
  color:#A1A1A1;
  outline: none;
  &::placeholder {
    color:#A1A1A1;
  }
`;

const SearchContainer = styled.div`
    padding: 1.5em;
    border-bottom 1px solid #E2E5ED;
`

const SearchInputWrapper = styled.div`
    display:flex;
    align-items: center;
    background-color: #F8F8FB;
    border-radius: 8px;
    padding-left: 0.5em;
`
const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`

const IconBackground = styled.div`
  background-color: #F8F8FB;
  padding: .3em .5em;
  border-radius: 8px;
  margin-left: .5em;
`
const FilterIconsWrapper = styled.div`
  display: flex;
`
const SearchTitle = styled.div`
  color: #001737;
  font-weight: 500;

  span {
    color: #A1A1A1;
    margin-left: 0.25em;
    font-weight: 400;
  }
`;

type SearchProps = {
  searchTitle: string;
  usersList: User[];
  multiSelect: boolean;
  shouldRefetch: boolean;
  setShouldRefetch: (shouldRefetch: boolean) => void;
  setUsersList: (student: User[]) => void;
  semester: string | null;
  studentType: string | null;
  degreeType: string | null;
  role: number;
  department: string | null;
};

function SearchUsersWrapper({ usersList, setUsersList, searchTitle, multiSelect, semester, studentType, degreeType, role, shouldRefetch, setShouldRefetch, department }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }
  const debouncedResults = useMemo(() => { return _.debounce(handleSearchChange, 300); }, []);
  useEffect(() => { return () => { debouncedResults.cancel(); }; });

  return (
    <SearchWrapper>
      <SearchContainer>
        <FilterWrapper>
          <SearchTitle>{searchTitle} <span>{users.length}</span></SearchTitle>
          {/* <FilterIconsWrapper>
            <IconBackground>
              <FilterAltIcon fontSize='inherit' />
            </IconBackground>
            <IconBackground>
              <MoreHorizIcon fontSize='inherit' />
            </IconBackground>
          </FilterIconsWrapper> */}
        </FilterWrapper>
        <SearchInputWrapper>
          <SearchIcon sx={{ color: '#A1A1A1', strokeWidth: 1, stroke: '#F8F8FB' }} />
          <SearchInput type='search' placeholder='Search..' onChange={debouncedResults} />
        </SearchInputWrapper>
      </SearchContainer>
      <UserCardsList users={users} setUsers={setUsers} searchTerm={searchTerm} setUsersList={setUsersList} usersList={usersList} multiSelect={multiSelect} semester={semester} studentType={studentType} degreeType={degreeType} role={role} shouldRefetch={shouldRefetch} setShouldRefetch={setShouldRefetch} department={department} />
    </SearchWrapper>
  )
}

export default SearchUsersWrapper