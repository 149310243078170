import OnboardingNavButtons from "../../components/OnboardingNavButtons/OnboardingNavButtons";
import OnboardingStepper from "../../components/OnboardingStepper/OnboardingStepper";
import OnboardingPersonal from "../../components/OnboardingPersonal/OnboardingPersonal";
import OnboardingSubPersonalFirst from "../../components/OnboardingPersonal/OnboardingSubPersonalFirst/OnboardingSubPersonalFirst";
import OnboardingSubPersonalSecond from "../../components/OnboardingPersonal/OnboardingSubPersonalSecond/OnboardingSubPersonalSecond";
import OnboardingEducation from "../../components/OnboardingEducation/OnboardingEducation";
import OnboardingSubEducationFirst from "../../components/OnboardingEducation/OnboardingSubEducationFirst/OnboardingSubEducationFirst";
import OnboardingInterests from "../../components/OnboardingInterests/OnboardingInterests";
import OnboardingSubinterestsFirst from "../../components/OnboardingInterests/OnboardingSubInterestsFirst/OnboardingSubInterestsFirst";
import OnboardingBuddyHelp from "../../components/OnboardingBuddyHelp/OnboardingBuddyHelp";
import OnboardingService from "../../services/onboardingService";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import OnboardingVM from "../../models/OnboardingVM";
import OnboardingProfilePicture from "../../components/OnboardingProfilePicture/OnboardingProfilePicture";
import OnboardingMotivation from "../../components/OnboardingMotivation/OnboardingMotivation";
import { useEffect, useState } from "react";
import { BuddyOnboardingStepsEnum, StudentOnboardingStepsEnum } from "../../enums/onboardingSteps-enum";
import { CircularProgress } from "@mui/material";
import { NavbarEnum } from "../../enums/navbar-enum";
import { OnboardingTypeEnum } from "../../enums/onboardingType-enum";
import "./Onboarding.css"
import { useLocation } from "react-router";

export default function Onboarding() {
    const location = useLocation();
    const { role } = location.state; // Retrieve the data from state
    const [activeStep, setActiveStep] = useState(role === OnboardingTypeEnum.Buddy ? BuddyOnboardingStepsEnum.None : StudentOnboardingStepsEnum.None)
    const [disableContinueButton, setDisableContinueButton] = useState(false)
    const [onboardingModel, setOnboardingModel] = useState<OnboardingVM>()
    const [firstMount, setFirstMount] = useState(true)
    const updateStepIfStart = (step: BuddyOnboardingStepsEnum | StudentOnboardingStepsEnum) => {
        if (step === BuddyOnboardingStepsEnum.Start)
            return BuddyOnboardingStepsEnum.Personal;
        return step;
    }
    useEffect(() => {
        setOnboardingData();
    }, [])

    useEffect(() => {
        if (onboardingModel && firstMount) {
            setFirstMount(false)
            setActiveStep(updateStepIfStart(onboardingModel.step));
        }

        if (onboardingModel && activeStep !== onboardingModel.step)
            onboardingModel.step = updateStepIfStart(activeStep);
    }, [onboardingModel, activeStep])

    async function setOnboardingData() {
        const response = await new OnboardingService().getOnboardingData();
        setOnboardingModel(response.data)
    }

    const renderSwitch = (activeStep: BuddyOnboardingStepsEnum | StudentOnboardingStepsEnum) => {
        if (role === OnboardingTypeEnum.Buddy) {
            switch (activeStep) {
                case BuddyOnboardingStepsEnum.Personal:
                    return <OnboardingPersonal setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingPersonal>
                case BuddyOnboardingStepsEnum.SubPersonalFirst:
                    return <OnboardingSubPersonalFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubPersonalFirst>
                case BuddyOnboardingStepsEnum.SubPersonalSecond:
                    return <OnboardingSubPersonalSecond setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubPersonalSecond>
                case BuddyOnboardingStepsEnum.Education:
                    return <OnboardingEducation setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingEducation>
                case BuddyOnboardingStepsEnum.SubEducationFirst:
                    return <OnboardingSubEducationFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubEducationFirst>
                case BuddyOnboardingStepsEnum.Motivation:
                    return <OnboardingMotivation setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingMotivation>
                case BuddyOnboardingStepsEnum.ProfilePicture:
                    return <OnboardingProfilePicture setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingProfilePicture>
                case BuddyOnboardingStepsEnum.Interests:
                    return <OnboardingInterests setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingInterests>
                case BuddyOnboardingStepsEnum.SubInterestsFirst:
                    return <OnboardingSubinterestsFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubinterestsFirst>
                case BuddyOnboardingStepsEnum.BuddyHelp:
                    return <OnboardingBuddyHelp setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingBuddyHelp>
            }
        } else {
            switch (activeStep) {
                case StudentOnboardingStepsEnum.Personal:
                    return <OnboardingPersonal setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingPersonal>
                case StudentOnboardingStepsEnum.SubPersonalFirst:
                    return <OnboardingSubPersonalFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubPersonalFirst>
                case StudentOnboardingStepsEnum.SubPersonalSecond:
                    return <OnboardingSubPersonalSecond setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubPersonalSecond>
                case StudentOnboardingStepsEnum.Education:
                    return <OnboardingEducation setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingEducation>
                case StudentOnboardingStepsEnum.SubEducationFirst:
                    return <OnboardingSubEducationFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubEducationFirst>
                case StudentOnboardingStepsEnum.ProfilePicture:
                    return <OnboardingProfilePicture setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingProfilePicture>
                case StudentOnboardingStepsEnum.Interests:
                    return <OnboardingInterests setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingInterests>
                case StudentOnboardingStepsEnum.SubInterestsFirst:
                    return <OnboardingSubinterestsFirst setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingSubinterestsFirst>
                case StudentOnboardingStepsEnum.BuddyHelp:
                    return <OnboardingBuddyHelp setOnboardingModel={setOnboardingModel} setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel}></OnboardingBuddyHelp>
            }
        }
    }
    return (
        <div>
            <div>
                <NavigationBar navbarEnum={NavbarEnum.StudentOnboarding}></NavigationBar>
            </div>
            {activeStep != BuddyOnboardingStepsEnum.None && activeStep != StudentOnboardingStepsEnum.None ?
                <div>
                    <OnboardingStepper type={role} activeStep={activeStep}></OnboardingStepper>
                    {renderSwitch(activeStep)}
                    <OnboardingNavButtons onboardingModel={onboardingModel} type={role} disableContinueButton={disableContinueButton} activeStep={activeStep} setActiveStep={setActiveStep}></OnboardingNavButtons>
                </div>
                :
                <div className="circular-progress-wrapper">
                    Test Test
                </div>
            }
        </div>
    );
}
