import http from "../http-common";
import {SearchUsersResponse} from "../models/Matchmaking";


class MatcmakingService {
    searchUsers(data: any) {
        return http.post<SearchUsersResponse>("/user", data);
    }

    matchUsers(data:any) {
        return http.post<any>("/connection", data);
    }
}

export default new MatcmakingService();