import { InterestOptions } from '../../models/InterestOptionsVM'
import './ChoiceOptionColumn.css'

interface Props {
    options: InterestOptions[]
    handleAnswerClick: Function
    numAnswersPerColumn?: number
}

const ChoiceOptionColumn: React.FC<Props> = ({ options, handleAnswerClick, numAnswersPerColumn }) => {
    return (<div className="choice-column">
        {options.map((option, index) => {
            let answerIndex = index;
            if(numAnswersPerColumn){
                answerIndex = index + numAnswersPerColumn;
            }
            return (
                <div
                    className={`choice-option ${option.selected ? "choice-option-selected" : ""} semiBold-font`}
                    key={answerIndex}
                    onClick={() => handleAnswerClick(answerIndex)}
                >
                    {option.value.name}
                </div>
            );
        })}
    </div>)
}

export default ChoiceOptionColumn