import { useEffect, useState } from 'react';
import { localStorageService } from '../../services/localStorageService';
import { LookupTypeEnum } from '../../enums/lookupType-enum';
import { InterestOptions } from '../../models/InterestOptionsVM';
import { UserProfileVM } from '../../models/UserProfileVM';
import { NavbarEnum } from '../../enums/navbar-enum';
import { Avatar } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DegreeType } from '../../enums/degreeType-enum';
import { StudentType } from '../../enums/studentType-enum';
import LanguageIcon from '@mui/icons-material/Language';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import UserProfileItem from '../../components/UserProfileItem/UserProfileItem';
import LookupService from '../../services/lookupService';
import UserService from '../../services/UserService';
import UserResponseModel from '../../models/UserReponseModel';
import "./UserProfile.css"

function UserProfile() {
    const [freeTimeInterests, setFreeTimeInterests] = useState([] as InterestOptions[])
    const [hobbies, setHobbies] = useState([] as InterestOptions[])
    const [userProfile, setUserProfile] = useState<UserProfileVM>({});
    const { id } = useParams();
    const getUserProfile = async () => {
        let userId;
        if (!id) {
            const key = "user_info";
            const cachedData = localStorageService.getItem(key);
            if (cachedData) {
                const data = JSON.parse(cachedData) as UserResponseModel;
                userId = data.id;
            }
        } else
            userId = id;

        if (userId) {
            let response = await new UserService().getUserProfile(userId);
            let data = response.data;
            if (data) {
                setUserProfile(data);
            }
        }
    }
    const setOptions = async () => {
        let response = await new LookupService().getByLookupType(LookupTypeEnum.FreeTime);
        let data = response.data.map((x) => {
            let selected = false;
            if (userProfile?.interests) {
                selected = userProfile.interests?.some(y => y.lookup_id === x.id);
            }
            const value: InterestOptions = { selected: selected, value: x };
            return value;
        });
        setFreeTimeInterests(data);

        response = await new LookupService().getByLookupType(LookupTypeEnum.Hobbies);
        data = response.data.map((x) => {
            let selected = false;
            if (userProfile?.hobbies) {
                selected = userProfile.hobbies?.some(y => y.lookup_id === x.id);
            }
            const value: InterestOptions = { selected: selected, value: x };
            return value;
        });
        setHobbies(data);
    }
    const showLanguages = () => {
        if (userProfile?.languages) {
            let langauges: string = '';
            userProfile.languages.map((x) => {
                langauges += x.name + " ";
            })
            return langauges;
        }
        return '';
    }
    const getDegreeString = (enumValue: DegreeType | undefined) => {
        switch(enumValue){
            case DegreeType.Bachelor:
                return "Bachelor program";
            case DegreeType.Master:
                return "Master program";
            default:
                return '';
        }
    }
    const getStudentTypeString = (enumValue: StudentType |undefined) => {
        switch(enumValue){
            case StudentType.Erasmus:
                return "Erasmus student";
            case StudentType.FullTimeIncoming:
                return "Full time incoming student";
            default:
                return '';
        }
    }
    useEffect(() => {
        getUserProfile();
    }, [id])
    useEffect(() => {
        if (freeTimeInterests.length === 0 || hobbies.length === 0)
            setOptions();
    }, [userProfile])
    function stringAvatar(name: string | undefined) {
        if (name) {
            return {
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        }
    }
    return (
        <div className="user-profile-wrapper">
            <NavigationBar navbarEnum={!id ? NavbarEnum.UserProfile : NavbarEnum.StudentBuddyMatchProfile}></NavigationBar>
            <div className="user-profile-main-card">
                <div className="user-profile-picture">
                    {
                        userProfile.image ? <img src={userProfile.image} alt="" />
                            : userProfile.name ? <Avatar {...stringAvatar(userProfile.name)} sx={{ width: 192, height: 192, fontSize: 100, backgroundColor: "#33CC99" }}></Avatar>
                                : null
                    }
                </div>
                <div className="user-profile-info">
                    <h4 className='bold-font user-profile-name-age'>{userProfile.name}<span className='thin-font'> {userProfile.age}</span></h4>
                    <h6 className='light-font'>{userProfile.country?.name}</h6>
                    <h4 className='light-font'>{userProfile.university_name}</h4>
                    <h6 className='light-font'>{userProfile.department?.name} - {getDegreeString(userProfile.degree)} - {getStudentTypeString(userProfile.is_erasmus)}</h6>
                    <div className="user-profile-language-prefs">
                        <div className="language-prefs-icon">
                            <LanguageIcon style={{ fontSize: "3rem", color: "#AAAAAA" }} />
                        </div>
                        <div className="language-prefs-text">
                            <p className='light-font language-prefs-first'>Language preferences</p>
                            <p className='bold-font'>{showLanguages()}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="interests-info">
                <div className="user-info-activities">
                    <h5 className='bold-font free-time-activities'>Free time activities</h5>
                    <div className="activities-display">
                        {freeTimeInterests.map((element, index) => (
                            <UserProfileItem key={index} text={element.value.name} selected={element.selected}></UserProfileItem>
                        ))}
                    </div>
                </div>
                <div className="user-info-hobbies">
                    <h5 className='bold-font free-time-activities'>Hobbies</h5>
                    <div className="hobbies-display">
                        {hobbies.map((element, index) => (
                            <UserProfileItem key={index} text={element.value.name} selected={element.selected}></UserProfileItem>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;