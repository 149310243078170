import "./About.css";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { NavbarEnum } from '../../enums/navbar-enum';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLocation } from "react-router";

export default function About() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99",
        contrastText: "#fff"
      },
    },
    typography: {
      "fontFamily": "Montserrat Regular",
      "fontSize": 11
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="about-us-container">
        <div>
          {type === 'loggedIn' ? <NavigationBar navbarEnum={NavbarEnum.AboutLoggedIn}></NavigationBar>
            : <NavigationBar navbarEnum={NavbarEnum.Landing}></NavigationBar>}
          <div className="about-us-content">
            <h2>{t("ABOUT.ABOUT_US")}</h2>
            <div className="about-us-text">
              <p>{t("ABOUT.MAIN_TEXT")}</p>
              <p>{t("ABOUT.SIDE_TEXT")}</p>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}