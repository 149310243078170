import { useNavigate } from 'react-router-dom';
import { NavbarEnum } from '../../enums/navbar-enum'
import NavigationBar from '../NavigationBar/NavigationBar'
import './PreRegistration.css'
import PreRegistrationCard from './PreRegistrationCard/PreRegistrationCard'

function PreRegistration() {
    const navigate = useNavigate();

    return (
        <div>
            <div className='leftHalf'>
                <NavigationBar navbarEnum={NavbarEnum.PreRegistration} />
                <div className="pre-registration-wrapper">
                    <div className="create-account-container">
                        <p className="semiBold-font create-your-account">
                            Create your account
                        </p>
                        <div className='preregistration-card-wrapper'>
                            <PreRegistrationCard text='You are an International Student' />
                            <PreRegistrationCard text='You want to be a Buddy' />
                            <PreRegistrationCard text='You want to create a University' />
                        </div>
                        <div className='mt-3 arrow-back-wrapper' role="button" onClick={() => navigate(-1)}>
                            <img className='left-arrow-icon' src={require('../../assets/icons/left-arrow-icon.png')} alt="image" />
                            <label className='semiBold-font back-label'>Back</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rightHalf'></div>
        </div>
    )
}

export default PreRegistration