import { Dispatch, SetStateAction } from "react";
import ApiService from "./ApiService";
import OnboardingVM from "../models/OnboardingVM";
import OnboardingPersonalVM from "../models/OnboardingPersonalVM";
import { OnboardingEducationVM } from "../models/OnboardingEducationVM";
import OnboardingInterestsVM from "../models/OnboardingInterestsVM";
import { LookupTypeEnum } from "../enums/lookupType-enum";
import { UniversityOnboardingVM } from "../models/UniversityOnboardingVM";
import OnboardingMotivation from "../models/OnboardingMotivationVM";
import { OnboardingTypeEnum } from "../enums/onboardingType-enum";
import { localStorageService } from "./localStorageService";
import { BuddyOnboardingStepsEnum, StudentOnboardingStepsEnum } from "../enums/onboardingSteps-enum";
import UserResponseModel from "../models/UserReponseModel";
import { UniversityEnum } from "../enums/university-enum";

interface AuthData {
  roles: string[];
}

class OnboardingService extends ApiService {
  role: OnboardingTypeEnum = OnboardingTypeEnum.None;
  universityName: string = "";
  constructor() {
    super("/profile");
    const key = "auth"
    const cachedData = localStorageService.getItem(key);
    if (cachedData) {
      var data = JSON.parse(cachedData) as AuthData;
      this.role = data.roles[0] === "Student" ? OnboardingTypeEnum.Student : OnboardingTypeEnum.Buddy
    }
    this.setUniversityName();
  }

  public handleContinueButtonChange(
    isValid: Function,
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>
  ) {
    if (isValid()) setDisableContinueButton(false);
    else setDisableContinueButton(true);
  }

  public async getOnboardingData() {
    return await this.get<OnboardingVM>("/step");
  }

  public async updatePersonal(onboardingVM: OnboardingVM) {
    const activeStep = onboardingVM.step;
    let model: OnboardingPersonalVM = {};
    if (this.role === OnboardingTypeEnum.Buddy) {
      switch (activeStep) {
        case BuddyOnboardingStepsEnum.Personal:
          model.age = onboardingVM.age !== null ? onboardingVM.age : 0;
          model.gender = onboardingVM.gender;
          model.phone_number = onboardingVM.phone_number
          break;
        case BuddyOnboardingStepsEnum.SubPersonalFirst:
          model.country = onboardingVM.country.lookup_id;
          break;
        case BuddyOnboardingStepsEnum.SubPersonalSecond:
          const languages = onboardingVM?.languages?.map((x) => x.lookup_id);
          model.languages = languages;
          break;
      }
      return await this.post<OnboardingPersonalVM, OnboardingPersonalVM>(
        "/personal_details",
        model
      );
    } else if (this.role === OnboardingTypeEnum.Student) {
      switch (activeStep) {
        case StudentOnboardingStepsEnum.Personal:
          model.age = onboardingVM.age !== null ? onboardingVM.age : 0;
          model.gender = onboardingVM.gender;
          model.phone_number = onboardingVM.phone_number
          break;
        case StudentOnboardingStepsEnum.SubPersonalFirst:
          model.country = onboardingVM.country.lookup_id;
          break;
        case StudentOnboardingStepsEnum.SubPersonalSecond:
          const languages = onboardingVM?.languages?.map((x) => x.lookup_id);
          model.languages = languages;
          break;
      }
      return await this.post<OnboardingPersonalVM, OnboardingPersonalVM>(
        "/personal_details",
        model
      );
    }
  }

  setUniversityName = () => {
    const userInfoKey = "user_info";
    const cachedDataUserInfo = localStorageService.getItem(userInfoKey);
    let userData;
    if (cachedDataUserInfo) {
      userData = JSON.parse(cachedDataUserInfo) as UserResponseModel;
      this.universityName = userData.university_name;
    }
  }

  updateSubEducationManheim = (model: OnboardingEducationVM, onboardingVM: OnboardingVM) => {
    if(this.role === OnboardingTypeEnum.Buddy){
      model.buddy_multiple_students = onboardingVM.buddy_multiple_students;
    } else if(this.role === OnboardingTypeEnum.Student){
      model.student_academy = onboardingVM.student_academy;
    }
  }

  updateSubEducationGoethe = (model: OnboardingEducationVM, onboardingVM: OnboardingVM) => {
    model.degree = onboardingVM.degree;
    model.year = onboardingVM.study_year.lookup_id;
    model.buddy_wiwir = onboardingVM.is_wiwir;
  }

  updateSubEducationFirst = (model: OnboardingEducationVM, onboardingVM: OnboardingVM) => {
    switch(this.universityName){
      case UniversityEnum.FIT:
        this.updateSubEducationManheim(model, onboardingVM);
        break;
      case UniversityEnum.GoetheEconomicsUniversity:
        this.updateSubEducationGoethe(model, onboardingVM);
        break;
      case UniversityEnum.MannheimUniversity:
        this.updateSubEducationManheim(model, onboardingVM);
        break;
      default:
        return false;
    }
  }

  public async updateEducation(onboardingVM: OnboardingVM) {
    const activeStep = onboardingVM.step;
    let model: OnboardingEducationVM = {};
    if (this.role === OnboardingTypeEnum.Buddy) {
      switch (activeStep) {
        case BuddyOnboardingStepsEnum.Education:
          model.department = onboardingVM.department.lookup_id;
          model.semester_id = onboardingVM.semester_id;
          break;
        case BuddyOnboardingStepsEnum.SubEducationFirst:
          this.updateSubEducationFirst(model, onboardingVM);
          break;
      }

      return await this.post<OnboardingEducationVM, OnboardingEducationVM>(
        "/education",
        model
      );
    } else if (this.role === OnboardingTypeEnum.Student) {
      switch (activeStep) {
        case StudentOnboardingStepsEnum.Education:
          model.department = onboardingVM.department.lookup_id;
          model.semester_id = onboardingVM.semester_id;
          break;
        case StudentOnboardingStepsEnum.SubEducationFirst:
          this.updateSubEducationFirst(model, onboardingVM);
          break;
      }

      return await this.post<OnboardingEducationVM, OnboardingEducationVM>(
        "/education",
        model
      )
    }
  }

  public async updateMotivation(onboardingVM: OnboardingVM) {
    const activeStep = onboardingVM.step;
    let model: OnboardingMotivation = {};
    if (activeStep === BuddyOnboardingStepsEnum.Motivation) {
      model.benefits = onboardingVM.benefits;
      model.involved_reason = onboardingVM.involved_reason;
      model.expectations = onboardingVM.expectations;

      return await this.post<OnboardingMotivation, OnboardingMotivation>(
        "/motivation",
        model
      );
    }
  }

  public async updateInterests(onboardingVM: OnboardingVM) {
    const activeStep = onboardingVM.step;
    let model: OnboardingInterestsVM = {};
    if (this.role === OnboardingTypeEnum.Buddy) {
      switch (activeStep) {
        case BuddyOnboardingStepsEnum.Interests:
          const lookupsInterests = onboardingVM?.interests?.map(
            (x) => x.lookup_id
          );
          model.lookup_ids = lookupsInterests;
          model.lookup_type = LookupTypeEnum.FreeTime;
          break;
        case BuddyOnboardingStepsEnum.SubInterestsFirst:
          const lookupsHobbies = onboardingVM?.hobbies?.map((x) => x.lookup_id);
          model.lookup_ids = lookupsHobbies;
          model.lookup_type = LookupTypeEnum.Hobbies;
          break;
        case BuddyOnboardingStepsEnum.BuddyHelp:
          const lookupsHelp = onboardingVM?.help?.map((x) => x.lookup_id);
          model.lookup_ids = lookupsHelp;
          model.lookup_type = LookupTypeEnum.HelpNeeded;
          break;
      }

      return await this.post<OnboardingInterestsVM, OnboardingInterestsVM>(
        "/interests",
        model
      );
    } else if (this.role === OnboardingTypeEnum.Student) {
      switch (activeStep) {
        case StudentOnboardingStepsEnum.Interests:
          const lookupsInterests = onboardingVM?.interests?.map(
            (x) => x.lookup_id
          );
          model.lookup_ids = lookupsInterests;
          model.lookup_type = LookupTypeEnum.FreeTime;
          break;
        case StudentOnboardingStepsEnum.SubInterestsFirst:
          const lookupsHobbies = onboardingVM?.hobbies?.map((x) => x.lookup_id);
          model.lookup_ids = lookupsHobbies;
          model.lookup_type = LookupTypeEnum.Hobbies;
          break;
        case StudentOnboardingStepsEnum.BuddyHelp:
          const lookupsHelp = onboardingVM?.help?.map((x) => x.lookup_id);
          model.lookup_ids = lookupsHelp;
          model.lookup_type = LookupTypeEnum.HelpNeeded;
          break;
      }

      return await this.post<OnboardingInterestsVM, OnboardingInterestsVM>(
        "/interests",
        model
      );
    }
  }

  public async uploadProfilePicture(onboardingVM: OnboardingVM, file: File) {
    const activeStep = onboardingVM.step;
    if (this.role === OnboardingTypeEnum.Buddy) {
      if (activeStep === BuddyOnboardingStepsEnum.ProfilePicture && file) {
        return await this.upload<File>("/image", file);
      }
    } else if (this.role === OnboardingTypeEnum.Student) {
      if (activeStep === StudentOnboardingStepsEnum.ProfilePicture && file) {
        return await this.upload<File>("/image", file);
      }
    }
  }

  public async UniversityOnboarding(onboardingVM: UniversityOnboardingVM) {
    return await this.post("/responsible_person", onboardingVM);
  }

  public async UniversityOnboardingProfilePicture(file: File) {
    return await this.upload<File>("/image", file);
  }
}

export default OnboardingService;
