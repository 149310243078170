import UserLoginVM from "../models/UserLoginVM";
import { localStorageService } from "./localStorageService";
import axios from "axios";
export interface LoginResponse {
  role: string;
  message: string;
}
export class AuthService {
  protected readonly baseURL = process.env.REACT_APP_API_URL;

  public async Login(loginModel: UserLoginVM) {
    localStorageService.clear();
    const response = await axios.post(`${this.baseURL}auth/login`, loginModel, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return { role: response.data.role, message: response.statusText };
  }

  public async Logout() {
    localStorageService.clear();
    await fetch(`${this.baseURL}auth/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
  }
}
