import { useState } from "react";
import { NavbarEnum } from "../../enums/navbar-enum";
import { OnboardingTypeEnum } from "../../enums/onboardingType-enum";
import { UniversityOnboardingStepsEnum } from "../../enums/universityOnboardingSteps-enum";
import { UniversityOnboardingVM } from "../../models/UniversityOnboardingVM";
import OnboardingNavButtons from "../../components/OnboardingNavButtons/OnboardingNavButtons";
import OnboardingStepper from "../../components/OnboardingStepper/OnboardingStepper";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import UniOnboardingResponsiblePerson from "../../components/UniOnboardingResponsiblePerson/UniOnboardingResponsiblePerson";
import UniOnboardingProfilePicture from "../../components/UniOnboardingPicture/UniOnboardingPicture";
import UniOnboardingAbout from "../../components/UniOnboardingAbout/UniOnboardingAbout";
import "./UniversityOnboarding.css"

export default function UniversityOnboarding() {
    const [activeStep, setActiveStep] = useState(UniversityOnboardingStepsEnum.ResponsiblePerson)
    const [disableContinueButton, setDisableContinueButton] = useState(false)
    const [onboardingModel, setOnboardingModel] = useState<UniversityOnboardingVM>({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: { country: "", city: "", street_name: "", street_number: "", zip: "" },
        image: undefined,
        description: "",
        name: ""
    });

    const renderSwitch = (activeStep: UniversityOnboardingStepsEnum) => {
        switch (activeStep) {
            case UniversityOnboardingStepsEnum.ResponsiblePerson:
                return <UniOnboardingResponsiblePerson setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel} setOnboardingModel={setOnboardingModel}></UniOnboardingResponsiblePerson>
            case UniversityOnboardingStepsEnum.ProfilePicture:
                return <UniOnboardingProfilePicture setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel} setOnboardingModel={setOnboardingModel}></UniOnboardingProfilePicture>
            case UniversityOnboardingStepsEnum.About:
                return <UniOnboardingAbout setDisableContinueButton={setDisableContinueButton} onboardingModel={onboardingModel} setOnboardingModel={setOnboardingModel}></UniOnboardingAbout>
        }
    }

    return (
        <div>
            <div>
                <NavigationBar navbarEnum={NavbarEnum.Login}></NavigationBar>
            </div>
            <div>
                <OnboardingStepper type={OnboardingTypeEnum.University} activeStep={activeStep}></OnboardingStepper>
                {renderSwitch(activeStep)}
                <OnboardingNavButtons universityOnboardingModel={onboardingModel} type={OnboardingTypeEnum.University} disableContinueButton={disableContinueButton} activeStep={activeStep} setActiveStep={setActiveStep}></OnboardingNavButtons>
            </div>
        </div>
    );
}