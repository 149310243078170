import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

interface AuthContextType {
    auth: AuthData;
    setAuth: (data: AuthData) => void;
}

interface AuthData {
    roles: string[];
}

const useAuth = (): AuthContextType => {
    const authContext = useContext(AuthContext);

    if (!authContext) {

        throw new Error("useAuth must be used within an AuthProvider");
    }

    return authContext;
};

export default useAuth;