import ApiService, { ApiResponse } from "./ApiService";
import { CreateUniversityVM } from "../models/CreateUniversityVM";
import { UniversityVM } from "../models/UniversityVM";
class UniService extends ApiService {
  constructor() {
    super("university");
  }
  public async getAll(): Promise<ApiResponse<UniversityVM[]>> {
    return this.get<UniversityVM[]>("/");
  }
  public async getInfo(): Promise<ApiResponse<UniversityVM>> {
    return this.get<UniversityVM>(`/info`);
  }

  public async create(
    user: CreateUniversityVM
  ): Promise<ApiResponse<CreateUniversityVM>> {
    return this.post<CreateUniversityVM, CreateUniversityVM>("/create", user);
  }

  public async update(
    id: number,
    user: CreateUniversityVM
  ): Promise<ApiResponse<CreateUniversityVM>> {
    return this.put<CreateUniversityVM>(`/${id}`, user);
  }

  public approveUser(userId: string) {
    return this.patch<any, any>(`/update/${userId}`, { userId: userId });
  }
  public rejectUser(userId: string) {
    return this.delete<any>(`/reject/${userId}`);
  }
}
export default UniService;
