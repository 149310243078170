import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StudentType } from "../../../enums/studentType-enum";
import { DegreeType } from "../../../enums/degreeType-enum";
import { localStorageService } from "../../../services/localStorageService";
import { LookupTypeEnum } from "../../../enums/lookupType-enum";
import { LookupVM } from "../../../models/LookupVM";
import OnboardingVM from "../../../models/OnboardingVM";
import OnboardingService from "../../../services/onboardingService";
import LookupService from "../../../services/lookupService";
import OnboardingLookupVM from "../../../models/OnboardingLookupVM";
import UserResponseModel from "../../../models/UserReponseModel";
import { UniversityEnum } from "../../../enums/university-enum";
import "./OnboardingSubEducationFirst.css";
import { StudentAcademyStatusEnum } from "../../../enums/studentAcademyStatus-enum";

const theme = createTheme({
  palette: {
    primary: {
      main: "#33CC99",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat Regular",
    fontSize: 11,
  },
});

interface Props {
  onboardingModel?: OnboardingVM;
  setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
  setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
}

const OnboardingSubEducationFirst: React.FC<Props> = ({
  setDisableContinueButton,
  onboardingModel,
  setOnboardingModel,
}) => {
  const [checkboxStudentType, setCheckboxStudentType] = useState({
    erasmusStudent:
      onboardingModel?.is_erasmus === StudentType.Erasmus ? true : false,
    fullTimeStudent:
      onboardingModel?.is_erasmus === StudentType.FullTimeIncoming
        ? true
        : false,
  });
  const [checkboxStudentDegree, setcheckboxStudentDegree] = useState({
    bachelor: onboardingModel?.degree === DegreeType.Bachelor ? true : false,
    master: onboardingModel?.degree === DegreeType.Master ? true : false,
  });
  const [checkboxWiWir, setCheckboxWiWir] = useState({
    yes: onboardingModel?.is_wiwir === true ? true : false,
    no: onboardingModel?.is_wiwir === false ? true : false,
  });
  const [checkboxAcademy, setCheckboxAcademy] = useState({
    summerAcademy:
      onboardingModel?.student_academy ===
      StudentAcademyStatusEnum.SummerAcademy
        ? true
        : false,
    winterAcademy:
      onboardingModel?.student_academy ===
      StudentAcademyStatusEnum.WinterAcademy
        ? true
        : false,
  });
  const [checkboxMoreThanOne, setCheckboxMoreThanOne] = useState({
    yes: onboardingModel?.buddy_multiple_students === true ? true : false,
    no: onboardingModel?.buddy_multiple_students === false ? true : false,
  });

  const [localOnboardingModel] = useState(onboardingModel);
  const [yearId, setYearId] = useState("");
  const [userUniversity, setUserUniversity] = useState("");
  const [userRole, setUserRole] = useState("");
  const [studyYears, setStudyYears] = useState([] as LookupVM[]);
  const { t } = useTranslation("");

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (
      checkboxName === "cbxErasmusStudent" ||
      checkboxName === "cbxFullTImeStudent"
    ) {
      if (checkboxName === "cbxErasmusStudent" && isChecked) {
        setCheckboxStudentType({
          erasmusStudent: true,
          fullTimeStudent: false,
        });
      } else if (checkboxName === "cbxFullTImeStudent" && isChecked) {
        setCheckboxStudentType({
          erasmusStudent: false,
          fullTimeStudent: true,
        });
      }
    } else {
      if (checkboxName === "cbxBachelor" && isChecked) {
        setcheckboxStudentDegree({ bachelor: true, master: false });
      } else if (checkboxName === "cbxMaster" && isChecked) {
        setcheckboxStudentDegree({ bachelor: false, master: true });
      }
    }
  };

  const handleWiWirCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (checkboxName === "chbxWiWirYes" && isChecked) {
      setCheckboxWiWir({ yes: true, no: false });
    } else if (checkboxName === "chbxWiWirNo" && isChecked) {
      setCheckboxWiWir({ yes: false, no: true });
    }
  };

  const handleAcademyCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (checkboxName === "chbxWinterAcademy" && isChecked) {
      setCheckboxAcademy({ summerAcademy: false, winterAcademy: true });
    } else if (checkboxName === "chbxSummerAcademy" && isChecked) {
      setCheckboxAcademy({ summerAcademy: true, winterAcademy: false });
    }
  };

  const handleMoreThanOneBuddyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (checkboxName === "chbxMoreThanOneYes" && isChecked) {
      setCheckboxMoreThanOne({ yes: true, no: false });
    } else if (checkboxName === "chbxMoreThanOneNo" && isChecked) {
      setCheckboxMoreThanOne({ yes: false, no: true });
    }
  };

  const handleYearIdChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setYearId(value);
  };

  function setUniversityName() {
    const userInfoKey = "user_info";
    const cachedDataUserInfo = localStorageService.getItem(userInfoKey);
    let userData;
    if (cachedDataUserInfo) {
      userData = JSON.parse(cachedDataUserInfo) as UserResponseModel;
      setUserUniversity(userData.university_name ?? "");
      setUserRole(userData.role);
    }
  }

  async function getStudyYears() {
    const key = "studyYears";
    const cachedData = localStorageService.getItem(key);
    if (cachedData) {
      setStudyYears(JSON.parse(cachedData));
    } else {
      const response = await new LookupService().getByLookupType(
        LookupTypeEnum.StudyYears
      );
      const data = response.data;
      localStorageService.setItem(key, JSON.stringify(data));
      setStudyYears(data);
    }
  }

  const isValidManheim = () => {
    if (userRole === "Student") {
      return (
        checkboxAcademy.summerAcademy != false ||
        checkboxAcademy.winterAcademy != false
      );
    } else if (userRole === "Buddy") {
      return (
        checkboxMoreThanOne.yes != false || checkboxMoreThanOne.no != false
      );
    } else return false;
  };

  const isValidGoethe = () => {
    return (
      (checkboxStudentDegree.bachelor != false ||
        checkboxStudentDegree.master != false) &&
      (checkboxWiWir.yes != false || checkboxWiWir.no != false) &&
      yearId !== ""
    );
  };

  const isValid = () => {
    switch (userUniversity) {
      // case UniversityEnum.FIT:
      //   return isValidManheim();
      case UniversityEnum.GoetheEconomicsUniversity:
        return isValidGoethe();
      case UniversityEnum.MannheimUniversity:
        return isValidManheim();
      default:
        return false;
    }
  };

  const updateManheimModel = () => {
    if (userRole === "Student") {
      if (localOnboardingModel) {
        if (checkboxAcademy.summerAcademy === true)
          localOnboardingModel.student_academy =
            StudentAcademyStatusEnum.SummerAcademy;
        else if (checkboxAcademy.winterAcademy === true)
          localOnboardingModel.student_academy =
            StudentAcademyStatusEnum.WinterAcademy;
      }
    } else if (userRole === "Buddy") {
      if (localOnboardingModel) {
        if (checkboxMoreThanOne.yes === true)
          localOnboardingModel.buddy_multiple_students = true;
        else if (checkboxMoreThanOne.no === true)
          localOnboardingModel.buddy_multiple_students = false;
      }
    }
  };

  const updateGoetheModel = () => {
    if (localOnboardingModel) {
      if (checkboxStudentDegree.bachelor)
        localOnboardingModel.degree = DegreeType.Bachelor;
      else if (checkboxStudentDegree.master)
        localOnboardingModel.degree = DegreeType.Master;

      if (yearId) {
        const yearLookup: OnboardingLookupVM = { lookup_id: yearId };
        localOnboardingModel.study_year = yearLookup;
      }
      if (checkboxWiWir.yes) localOnboardingModel.is_wiwir = true;
      else localOnboardingModel.is_wiwir = false;
    }
  };

  const updateOnboardingModel = () => {
    switch (userUniversity) {
      // case UniversityEnum.FIT:
      //   return updateManheimModel();
      case UniversityEnum.GoetheEconomicsUniversity:
        return updateGoetheModel();
      case UniversityEnum.MannheimUniversity:
        return updateManheimModel();
      default:
        return false;
    }
  };

  useEffect(() => {
    new OnboardingService().handleContinueButtonChange(
      isValid,
      setDisableContinueButton
    );
    updateOnboardingModel();
    if (localOnboardingModel?.study_year)
      setYearId(localOnboardingModel.study_year.lookup_id);
  }, [
    yearId,
    checkboxStudentDegree,
    checkboxStudentType,
    checkboxWiWir,
    checkboxAcademy,
    checkboxMoreThanOne,
  ]);

  useEffect(() => {
    getStudyYears();
    setUniversityName();
    if (localOnboardingModel?.study_year)
      setYearId(localOnboardingModel.study_year.lookup_id);
  }, []);

  const renderManheimPartStudent = () => {
    return userUniversity === UniversityEnum.MannheimUniversity && userRole === "Student" ? (
      <div className="personal-wrapper">
        <div className="choose-language-wrapper">
          <div className="choose-checkbox-education">
            <p className="bold-font m-0">
              Are you taking part in the Winter Academy/Summer Academy
            </p>
            <Container
              sx={{ display: "flex", justifyContent: "center" }}
              component="main"
              maxWidth="xs"
            >
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAcademyCheckboxChange}
                      checked={checkboxAcademy.winterAcademy}
                      name="chbxWinterAcademy"
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAcademyCheckboxChange}
                      checked={checkboxAcademy.summerAcademy}
                      name="chbxSummerAcademy"
                    />
                  }
                  label="No"
                />
              </ThemeProvider>
            </Container>
          </div>
        </div>
      </div>
    ) : null;
  };

  const renderManheimPartBuddy = () => {
    return userUniversity === UniversityEnum.MannheimUniversity && userRole === "Buddy" ? (
      <div className="personal-wrapper">
        <div className="choose-language-wrapper">
          <div className="choose-checkbox-education">
            <p className="bold-font m-0">
              Do you want more than one international student as a buddy?
            </p>
            <Container
              sx={{ display: "flex", justifyContent: "center" }}
              component="main"
              maxWidth="xs"
            >
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleMoreThanOneBuddyChange}
                      checked={checkboxMoreThanOne.yes}
                      name="chbxMoreThanOneYes"
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleMoreThanOneBuddyChange}
                      checked={checkboxMoreThanOne.no}
                      name="chbxMoreThanOneNo"
                    />
                  }
                  label="No"
                />
              </ThemeProvider>
            </Container>
          </div>
        </div>
      </div>
    ) : null;
  };

  const renderGoethePart = () => {
    return userUniversity === UniversityEnum.GoetheEconomicsUniversity ? (
      <div className="personal-wrapper">
        <div className="choose-language-wrapper">
          <div className="choose-checkbox-education">
            <p className="bold-font m-0">
              Did you take part in WiWir Buddy Program Goethe before?
            </p>
            <Container
              sx={{ display: "flex", justifyContent: "center" }}
              component="main"
              maxWidth="xs"
            >
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleWiWirCheckboxChange}
                      checked={checkboxWiWir.yes}
                      name="chbxWiWirYes"
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleWiWirCheckboxChange}
                      checked={checkboxWiWir.no}
                      name="chbxWiWirNo"
                    />
                  }
                  label="No"
                />
              </ThemeProvider>
            </Container>
          </div>
          <div className="choose-checkbox-education mt-5">
            <p className="bold-font m-0">{t("EDUCATION.CHOOSE_YOUR_DEGREE")}</p>
            <Container
              sx={{ display: "flex", justifyContent: "center" }}
              component="main"
              maxWidth="xs"
            >
              <ThemeProvider theme={theme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxStudentDegree.bachelor}
                      name="cbxBachelor"
                    />
                  }
                  label={t("EDUCATION.BACHELOR")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxStudentDegree.master}
                      name="cbxMaster"
                    />
                  }
                  label={t("EDUCATION.MASTER")}
                />
              </ThemeProvider>
            </Container>
          </div>
          <p className="bold-font mt-5">
            In which semester will you be when you want to participate as a
            buddy?
          </p>
          <Container sx={{ width: "250px" }} component="main" maxWidth="xs">
            <ThemeProvider theme={theme}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">Semester</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Semester"
                  name="yearId"
                  value={yearId}
                  onChange={handleYearIdChange}
                >
                  {studyYears.map((element) => {
                    return (
                      <MenuItem key={element.id} value={element.id}>
                        {element.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ThemeProvider>
          </Container>
        </div>
      </div>
    ) : null;
  };

  return (
    <div>
      {renderGoethePart()}
      {renderManheimPartStudent()}
      {renderManheimPartBuddy()}
    </div>
  );
};

export default OnboardingSubEducationFirst;
