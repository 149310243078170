import './UserProfileItem.css'

interface Props {
    text: string,
    selected: boolean
}

const UserProfileItem: React.FC<Props> = ({ text, selected }) => {
    return (
        <div className={selected ? "medium-font user-info-item  user-profile-selected-item" : "medium-font user-info-item"}>
            {text}
        </div>
    )
}

export default UserProfileItem;