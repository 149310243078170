import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import "./SearchInput.css";
type Props = {
  placeholder: string;
  handleSearch: any;
};

export default function SearchInput({ placeholder, handleSearch }: Props) {
  return (
    <TextField
      className="search"
      size="small"
      placeholder={placeholder}
      onChange={(value) => handleSearch(value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search">
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          color: "#9F8989",
          fontSize: "1rem",
          borderRadius: "20px",
          background: "#FFFFFF",
          width: "28rem",
        },
      }}
    />
  );
}
