import axios, { AxiosInstance, AxiosError } from 'axios';
import { NavigateFunction } from 'react-router';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-type': 'application/json',
    },
    withCredentials: true,
});

export const authInterceptor = (setAuth: any, navigate: any) => {
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error: AxiosError) => {
            if (error.response?.status === 401) {
                // window.location.href = '/login';
                navigate('/login');
                setAuth(null);
            }
            return Promise.reject(error);
        }
    );
};

export default axiosInstance;