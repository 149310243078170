import './Landing.css'
import NavigationBar from '../NavigationBar/NavigationBar';
import { NavbarEnum } from '../../enums/navbar-enum';

function Landing() {
    return (
        <div>
            <NavigationBar navbarEnum={NavbarEnum.Landing} />
            <div className='landing-container'>
                <div className="landing-wrapper">
                    <div className="landing-text">
                        <div className='semiBold-font student-life'>
                            <p>Student life just got</p>
                            <p>better with <label className='student-life-green'>BuddyMatch</label></p>
                        </div>
                        <p className='regular-font are-you-looking'>Are you looking for an easy way to meet new friends during your student exchange program</p>
                        <p className='bold-font'>Buddy Match is here to help</p>
                        <div>
                            <button className='landing-button explore-more-button'>Explore more</button>
                        </div>
                    </div>
                    <div className="landing-image">
                        <img src={require('../../assets/images/female-student-pic.jpeg')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing