import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import PortraitIcon from '@mui/icons-material/Portrait';
import InterestsIcon from '@mui/icons-material/Interests';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import { useTranslation } from 'react-i18next';
import { OnboardingTypeEnum } from '../../enums/onboardingType-enum';
import { UniversityOnboardingStepsEnum } from '../../enums/universityOnboardingSteps-enum';
import "./OnboardingStepper.css"
import { BuddyOnboardingStepsEnum, StudentOnboardingStepsEnum } from '../../enums/onboardingSteps-enum';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        left: 'calc(-50% + 6px)',
        right: 'calc(50% + 6px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#33CC99',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#33CC99',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean, subCategory?: boolean };

}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    marginTop: ownerState.subCategory ? '12px' : '0px',
    width: ownerState.subCategory ? 20 : 50,
    height: ownerState.subCategory ? 20 : 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#33CC99',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#33CC99',
    }),
}));


interface Props {
    activeStep: BuddyOnboardingStepsEnum | StudentOnboardingStepsEnum | UniversityOnboardingStepsEnum
    type: OnboardingTypeEnum
}

const typographyTheme = createTheme({
    typography: {
        fontFamily: 'Montserrat Medium',
    },
});

const OnboardingStepper: React.FC<Props> = ({ activeStep, type }) => {
    const { t } = useTranslation("");
    let steps;
    if (type === OnboardingTypeEnum.Buddy) {
        steps =
            [{ key: "Personal", value: t("ONBOARDING_STEPPER.PERSONAL") },
            { key: "Personal-1", value: '' },
            { key: "Personal-2", value: '' },
            { key: "Education", value: t("ONBOARDING_STEPPER.EDUCATION") },
            { key: "Education-1", value: '' },
            { key: "Motivation", value: t("ONBOARDING_STEPPER.MOTIVATION") },
            { key: "Profile picture", value: t("ONBOARDING_STEPPER.PROFILE_PICTURE") },
            { key: "Interests", value: t("ONBOARDING_STEPPER.INTERESTS") },
            { key: "Interests-1", value: '' },
            { key: "Buddy Help", value: t("ONBOARDING_STEPPER.BUDDY_HELP") },
            ]
    } else if (type === OnboardingTypeEnum.Student) {
        steps =
            [{ key: "Personal", value: t("ONBOARDING_STEPPER.PERSONAL") },
            { key: "Personal-1", value: '' },
            { key: "Personal-2", value: '' },
            { key: "Education", value: t("ONBOARDING_STEPPER.EDUCATION") },
            { key: "Education-1", value: '' },
            { key: "Profile picture", value: t("ONBOARDING_STEPPER.PROFILE_PICTURE") },
            { key: "Interests", value: t("ONBOARDING_STEPPER.INTERESTS") },
            { key: "Interests-1", value: '' },
            { key: "Buddy Help", value: t("ONBOARDING_STEPPER.BUDDY_HELP") },
            ]
    } else {
        steps = [
            { key: "Responsible person", value: "Responsible person" },
            { key: "Profile picture", value: "Profile picture" },
            { key: "About university", value: "About university" }
        ];
    }

    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed } = props;
        let subCategory = false;

        //Subcategories will not have icons, instead empty element will be used '<></>'
        let icons: { [index: string]: React.ReactElement };
        if (type === OnboardingTypeEnum.Buddy) {
            icons = {
                1: <PersonIcon />,
                2: <></>,
                3: <></>,
                4: <SchoolIcon />,
                5: <></>,
                6: <AutoAwesomeIcon />,
                7: <PortraitIcon />,
                8: <InterestsIcon />,
                9: <></>,
                10: <HandshakeIcon />
            }
        } else if (type === OnboardingTypeEnum.Student) {
            icons = {
                1: <PersonIcon />,
                2: <></>,
                3: <></>,
                4: <SchoolIcon />,
                5: <></>,
                6: <PortraitIcon />,
                7: <InterestsIcon />,
                8: <></>,
                9: <HandshakeIcon />
            }
        } else {
            icons = {
                1: <PersonIcon />,
                2: <PortraitIcon />,
                3: <SchoolIcon />,

            }
        }

        if (type === OnboardingTypeEnum.Student || type === OnboardingTypeEnum.Buddy) {
            let emptyElementsPositions;
            if (type === OnboardingTypeEnum.Buddy)
                emptyElementsPositions = [2, 3, 5, 9];
            else
                emptyElementsPositions = [2, 3, 5, 8];

            if (emptyElementsPositions.find((x) => x == Number(props.icon))) {
                subCategory = true;
            }
        }

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active, subCategory }}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <div className='wrapper-stepper semiBold-font'>
            <Stack sx={{ width: '90%', marginTop: "5rem" }} spacing={4}>
                <Stepper alternativeLabel activeStep={activeStep - 1} connector={<ColorlibConnector />}>
                    {steps.map((step) => (
                        <Step key={step.key}>
                            <ThemeProvider theme={typographyTheme}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{step.value}</StepLabel>
                            </ThemeProvider>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </div>
    );
}

export default OnboardingStepper