import { createContext, useState, ReactNode, useEffect } from "react";

interface AuthData {
    roles: string[];
}

interface AuthContextType {
    auth: AuthData;
    setAuth: (data: AuthData) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {

    const storedAuth = localStorage.getItem("auth");
    const initialAuthData: AuthData = storedAuth ? JSON.parse(storedAuth) : {};

    const [auth, setAuth] = useState<AuthData>(initialAuthData);

    // Update localStorage whenever auth changes
    useEffect(() => {
        localStorage.setItem("auth", JSON.stringify(auth));
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
