import React from "react";
import styled from "styled-components";

const StyledCenteredDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif !important;
`;

const StyledWelcomeMessage = styled.div`
  font-size: 3rem;
  font-weight: 300;
`;

const StyledInfoMessage = styled.div`
  font-size: 1.2rem;
`;

function Dashboard() {
  return (
    <>
      <StyledCenteredDiv>
        <StyledWelcomeMessage>👋 Welcome!</StyledWelcomeMessage>
        <StyledInfoMessage>
          Currently this page is under construction 🏗️
        </StyledInfoMessage>
      </StyledCenteredDiv>
    </>
  );
}

export default Dashboard;