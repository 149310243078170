import UserCard from './UserCard';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { User } from '../../models/User';
import MatchmakingService from '../../services/MatchmakingService';
import { SearchUsersResponse } from '../../models/Matchmaking';
import { AxiosResponse } from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Atif = styled.div`
  max-height: 100%;
`;

const UserCardListWrapper = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

`;


const SemesterLabel = styled.div`
  color:  #AAAAAA;
  text-align:center;
  padding-top: 1em;
`

type UserCardListProps = {
    searchTerm: string;
    multiSelect: boolean;
    usersList: User[];
    setUsersList: (student: User[]) => void;
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    users: User[];
    shouldRefetch: boolean;
    setShouldRefetch: (shouldRefetch: boolean) => void;
    semester: string | null;
    studentType: string | null;
    degreeType: string | null;
    department: string | null;
    role: number;
};

function UserCardsList({
    searchTerm,
    multiSelect,
    setUsersList,
    usersList,
    semester,
    studentType,
    degreeType,
    role,
    setShouldRefetch,
    shouldRefetch,
    department,
    users,
    setUsers
}: UserCardListProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [hasMore, setHasMore] = useState(true);
    let cursor = useRef<string | null>(null);
    const id = role.toString();
    const paginationLimit = 20;

    const fetchData = () => {
        //   setUsers([])
        cursor.current = null;
        MatchmakingService.searchUsers({
            department: department === "" ? null : department,
            role: role,
            take: paginationLimit,
            student_type: studentType === "" ? null : (studentType !== null ? parseInt(studentType, 10) : null),
            degree_type: degreeType === "" ? null : (degreeType !== null ? parseInt(degreeType, 10) : null),
            semester_id: semester,
            cursor: null,
            search: searchTerm,
            connection_status: 1,
            buddy_status: role === 0 ? 1 : null, //if role is 'buddy', pass buddy_status: 1
            student_status: role === 1 ? 1 : null, //if role is 'student', pass student_status: 1
        })
            .then((response: AxiosResponse<SearchUsersResponse>) => {
                cursor.current = response.data.data.next_cursor;
                setUsers(response.data.data.users);
                setHasMore(cursor.current !== null);
            })
    }

    useEffect(() => {
        if (semester !== "") {
            fetchData();

        } else {
            setUsers([]);
        }
    }, [searchTerm, semester, studentType, degreeType, department]);

    useEffect(() => {

        if (shouldRefetch) {
            setShouldRefetch(false);
            fetchData()
        }
    }, [shouldRefetch]);

    const fetchMoreData = () => {

        MatchmakingService.searchUsers({
            department: null,
            role: role,
            take: paginationLimit,
            student_type: studentType === "" ? null : (studentType !== null ? parseInt(studentType, 10) : null),
            degree_type: degreeType === "" ? null : (degreeType !== null ? parseInt(degreeType, 10) : null),
            semester_id: semester,
            cursor: cursor.current,
            search: searchTerm,
            connection_status: 1,
            buddy_status: 1,
        })
            .then((response: AxiosResponse<SearchUsersResponse>) => {
                setUsers(prevUsers => [...prevUsers, ...response.data.data.users]);
                cursor.current = response.data.data.next_cursor;
                setHasMore(cursor.current !== null);
            })
    }

    return (
        <Atif >
            {semester === "" ? (
                <SemesterLabel>Choose a semester.</SemesterLabel>
            ) : (
                < UserCardListWrapper ref={containerRef} id={id}>

                    <InfiniteScroll
                        dataLength={users.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<p>Loading...</p>}
                        scrollableTarget={id}
                    >
                        <TransitionGroup>
                            {users.map(user => (
                                <CSSTransition key={user.id} classNames="fade" timeout={100}>
                                    <UserCard
                                        key={user.id}
                                        user={user}
                                        checked={usersList.some((student: User) => student.id === user.id)}
                                        multiSelectMode={multiSelect}
                                        setUsersList={setUsersList}
                                        usersList={usersList}
                                    />
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </InfiniteScroll>
                </UserCardListWrapper>
            )}
        </Atif >
    );
}

export default UserCardsList;
