import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import { NONAME } from "dns";
import Menu from "@mui/material/Menu";
import useAuth from "../../hooks/useAuth";
import { Avatar } from "@mui/material";
import { AuthService } from "../../services/authService";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const location = useLocation();

  const menuProps: { [key: string]: string } = {
    "/matchmaking": "Matchmaking",
    "/student-requests": "Student requests",
    "/buddies": "Buddies",
    "/students": "Students",
    "/buddy-requests": "Buddy requests",
    "/matches": "Matches",
    "/dashboard": "Dashboard",
    // Add more menu items as needed
  };
  interface AuthData {
    roles: string[];
  }

  const [anchorEl, setAnchorEl] = useState(null);


  const currentPathname = location.pathname;
  const currentLabel = menuProps[currentPathname];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const handleLogout = async () => {
    handleMenuClose();
    const emptyAuthData: AuthData = {
      roles: []
    };
    await new AuthService().Logout();
    setAuth(emptyAuthData);
    navigate('/login');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        backgroundColor: "#F5F5F5",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ boxShadow: 'none', borderBottom: '1px solid #E2E5ED;' }}>
        <Toolbar sx={{ backgroundColor: "#FFFFFF" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{ color: "#001737" }} />
          </IconButton>
          <Typography variant="h6" noWrap sx={{
            textTransform: 'capitalize',
            fontSize: '1rem',
            color: 'black'
          }} component="div">
            {currentLabel}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>

            <div style={{ flexGrow: 1 }}></div>
            <IconButton
              edge="end"
              color="inherit"
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <Avatar src="" alt="Profile Image" />
            </IconButton>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
            {/* <div style={{ flexGrow: 1 }}></div> */}
          </Box>
        </Toolbar>

      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <Sidebar />
      </Drawer>
      <Main open={open} sx={{ backgroundColor: "#F8F8FB" }} className="test">
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box >
  );
}