import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./Contact.css";
import InputLabel from "@mui/material/InputLabel";
import greenTheme from "../../utils/greenTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTranslation } from "react-i18next";
import UniService from "../../services/UniService";
import { CreateUniversityVM } from "../../models/CreateUniversityVM";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { NavbarEnum } from '../../enums/navbar-enum';
import  EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PublicIcon from '@mui/icons-material/Public';
export default function Contact() {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState<CreateUniversityVM>({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({ ...prevState, [name]: value }));
  };
  const sendEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await new UniService().create(contactData);
    } catch (error) {}
  };

  const theme = createTheme({
    palette: {
        primary: {
            main: "#33CC99",
            contrastText: "#fff"
        },
    },
    typography: {
        "fontFamily": "Montserrat Regular",
        "fontSize": 12
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <NavigationBar navbarEnum={NavbarEnum.Landing} />
        <div className="contact-container-main">
          <div className="contact-form-main">
            <div className="contact-info color-green">
              <h3 className="title">{t("CONTACT.GET_IN_TOUCH")}</h3>
              <p className="text">{t("CONTACT.INTRODUCTION")}</p>

              <div className="info">
                <div className="information">
                <EmailIcon className="icon" />
                  <p>{t("CONTACT.MAIL")}</p>
                </div>
                <div className="information">
                <PublicIcon className="icon" />
                  <p>{t("CONTACT.PHONE_NUMBER_BOSNIA")}</p>
                </div>
                <div className="information">
                <PublicIcon className="icon" />
                  <p>{t("CONTACT.PHONE_NUMBER_GERMANY")}</p>
                </div>
                <div className="information">
                <PublicIcon className="icon" />
                  <p>{t("CONTACT.PHONE_NUMBER_TURKEY")}</p>
                </div>    
              </div>

              <div className="social-media">
                <p>Connect with us :</p>
                <div className="social-icons">
                  <a href="#">
                    <i className="fab fa-facebook-f"><FacebookIcon /></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"><InstagramIcon /></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"><LinkedInIcon /></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form className="contact-form-tag" onSubmit={sendEmail}>
                  <h3 className="align-left">{t("CONTACT.CONTACT_FORM")}</h3>
                  <div className="row margin margin-btm-m">
                    <div className="col no-padding margin-right">
                      <InputLabel
                        htmlFor="first-name"
                        className="align-left margin-btm-sml"
                      >
                        {t("FEEDBACK.FORM.FIRST_NAME")}
                      </InputLabel>
                      <CustomTextField
                        title={"FEEDBACK.FORM.FIRST_NAME"}
                        field={"firstName"}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col no-padding">
                      <InputLabel
                        htmlFor="lastName"
                        className="align-left margin-btm-sml"
                      >
                        {t("FEEDBACK.FORM.LAST_NAME")}
                      </InputLabel>
                      <CustomTextField
                        title={"FEEDBACK.FORM.LAST_NAME"}
                        field={"last_name"}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row margin margin-btm-m">
                    <InputLabel htmlFor="email" className="align-left margin-btm-sml">
                      <DraftsIcon /> {t("FEEDBACK.FORM.EMAIL")}
                    </InputLabel>
                    <CustomTextField
                      title={"FEEDBACK.FORM.EMAIL_LABEL"}
                      field={"email"}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="feedback-submit-button">
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t("CONTACT.FORM.SEND_BUTTON")}
                    </Button>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
interface CustomTextFieldProps {
  title: string;
  field: string;
  onChange: any;
}

const CustomTextField = (props: CustomTextFieldProps) => {
  const { t } = useTranslation();
  const { title, field, onChange } = props;
  return (
    <TextField
      size="small"
      label={t(title)}
      id={field}
      name={field}
      onChange={onChange}
    />
  );
};