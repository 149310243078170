import { Box, Container, CssBaseline, Grid, TextField, ThemeProvider, createTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UniversityOnboardingVM } from "../../models/UniversityOnboardingVM";
import OnboardingService from "../../services/onboardingService";
import "./UniOnboardingAbout.css"

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    onboardingModel?: UniversityOnboardingVM;
    setOnboardingModel: Dispatch<SetStateAction<UniversityOnboardingVM>>;
}

const UniOnboardingAbout: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const [about, setAbout] = useState("");
    const [universityName, setUniversityName] = useState("");
    const defaultTheme = createTheme({
        palette: {
            primary: {
                main: "#33CC99", //this overide blue color with base green color of our app
                contrastText: "#fff",
            },
        },
        typography: {
            fontFamily: "Montserrat Regular",
            fontSize: 11,
        },
    });
    const updateOnboardingModel = () => {
        if (onboardingModel) {
            if (about)
                onboardingModel.description = about;
            if (universityName)
                onboardingModel.name = universityName;
            setOnboardingModel(onboardingModel);
        }
    }
    const isValid = () => {
        return about.length > 0 && universityName.length > 0;
    }
    useEffect(() => {
        updateOnboardingModel();
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
    }, [about, universityName])
    useEffect(() => {
        if (onboardingModel){
            setAbout(onboardingModel.description)
            setUniversityName(onboardingModel.name)
        }
            
    }, [])

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <p className="bold-font uni-onboarding-about-p">Give us short description of your University</p>
                    <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="uni name"
                                    required
                                    fullWidth
                                    id="uniName"
                                    label="University Name"
                                    onChange={e => setUniversityName(e.target.value)}
                                    value={universityName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-static"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    variant="filled"
                                    value={about}
                                    onChange={e => setAbout(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default UniOnboardingAbout