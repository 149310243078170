import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UniService from "../../services/UniService";
import { useEffect, useState } from "react";
import { UniversityVM } from "../../models/UniversityVM";
import { ThemeProvider, createTheme } from "@mui/material";
export default function UniversityInfo() {
  const [universityData, setData] = useState<UniversityVM>();
  const setUniversityInfo = async () => {
    const response = await new UniService().getInfo();
    setData(response.data);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99",
        contrastText: "#fff"
      },
    },
    typography: {
      "fontFamily": "Montserrat Medium",
      "fontSize": 14
    }
  });

  useEffect(() => {
    setUniversityInfo().then();
  }, []);

  if (universityData === undefined) return <></>;
  return (
    <ThemeProvider theme={theme}>

    <Card
      sx={{
        display: "flex",
        height: 500,
        width: "80%",
        marginLeft: "10%",
        marginTop: 2,
      }}
      >
      <CardMedia
        component="img"
        sx={{ width: "40%" }}
        image={universityData.university_image_url}
        alt="Live from space album cover"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          padding: 3,
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h4">
            {universityData.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            sx={{ marginBottom: 5 }}
            ></Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="medium-font"
            sx={{ marginBottom: 2 }}
          >
            {universityData.description}
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}></Box>
      </Box>
    </Card>
            </ThemeProvider>
  );
}
