import { localStorageService } from "../../services/localStorageService";
import { useNavigate, Link } from "react-router-dom";
import { NavbarEnum } from "../../enums/navbar-enum";
import React, { useEffect, useState } from "react";
import UserResponseModel from "../../models/UserReponseModel";
import UserService from "../../services/UserService";
import AccountMenu from "../AccountMenu/AccountMenu";
import "./NavigationBar.css";

interface Props {
  navbarEnum: NavbarEnum;
}

const NavigationBar: React.FC<Props> = ({ navbarEnum }) => {
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [role, setRole] = useState("");
  const [buddyStudentProfileId, setbuddyStudentProfileId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getUserInfo();
  }, []);
  const userLoggedIn = () => {
    if (
      navbarEnum === NavbarEnum.Feed ||
      navbarEnum === NavbarEnum.StudentOnboarding ||
      navbarEnum === NavbarEnum.UserProfile ||
      navbarEnum === NavbarEnum.University ||
      navbarEnum === NavbarEnum.StudentBuddyMatchProfile ||
      navbarEnum === NavbarEnum.AboutLoggedIn
    )
      return true;
    return false;
  };
  const getUserInfo = async () => {
    if (userLoggedIn()) {
      let data;
      const key = "user_info";
      const cachedData = localStorageService.getItem(key);
      if (cachedData) {
        data = JSON.parse(cachedData) as UserResponseModel;
      } else {
        const response = await new UserService().getUser();
        data = response.data;
        localStorageService.setItem(key, JSON.stringify(data));
      }
      setUserProfilePicture(data.user_image_url);
      setRole(data.role);
      setDisplayName(data.first_name + " " + data.last_name);
      setFirstName(data.first_name);
      if(data.role === "Buddy")
        setbuddyStudentProfileId(data.student_profile_id);
      else
        setbuddyStudentProfileId(data.buddy_profile_id);
    }
  };

  function ContactSection(navbarEnum: NavbarEnum) {
    if (navbarEnum === NavbarEnum.Landing)
      return (
        <div className="contact-section">
          <ul className="navigation-bar__list">
            <li className="navigation-bar__item semiBold-font">
              <a href="feedback">Feedback</a>
            </li>
            <li className="navigation-bar__item semiBold-font">
              <a href="contact">Contact</a>
            </li>
            <li className="navigation-bar__item semiBold-font">
              <a href="about">About</a>
            </li>
          </ul>
        </div>
      );
  }
  function LoggedInSection() {
    if (userLoggedIn() && navbarEnum !== NavbarEnum.StudentOnboarding) {
      //Do not show this section although user is logged in when it's on Student/Buddy Oboarding page.
      return (
        <div className="nav-logged-in-section">
          <div
            className={`nav-logged-in-section-item medium-font ${navbarEnum === NavbarEnum.Feed
              ? "default-navigation-bar-selected-option"
              : ""
              }`}
            onClick={() => navigate("/feed")}
          >
            Feed
          </div>
          <div
            className={`nav-logged-in-section-item medium-font ${navbarEnum === NavbarEnum.AboutLoggedIn
              ? "default-navigation-bar-selected-option"
              : ""
              }`}
            onClick={() => navigate("/about?type=loggedIn")}
          >
            About
          </div>
          <div
            className={`nav-logged-in-section-item medium-font ${navbarEnum === NavbarEnum.University
              ? "default-navigation-bar-selected-option"
              : ""
              }`}
            onClick={() => navigate("/university-info")}
          >
            University
          </div>

          {
            isStringDifferentFromEmptyGuid(buddyStudentProfileId) ?

              <div className={`nav-logged-in-section-item medium-font ${navbarEnum === NavbarEnum.StudentBuddyMatchProfile
                ? "default-navigation-bar-selected-option"
                : ""
                }`}
                onClick={() => navigate(`/user-profile/${buddyStudentProfileId}`)}
              >
                {role === "Student" ? "Buddy's profile" : "Student's profile"}
              </div>
              : ""
          }
        </div>
      );
    }
  }

  function CreateAccount(navbarEnum: NavbarEnum) {
    if (navbarEnum == NavbarEnum.Landing)
      return (
        <button
          onClick={() => navigate("/pre-registration")}
          className="create-account-button landing-button"
        >
          Create account
        </button>
      );
  }

  function ShowLogo(navbarEnum: NavbarEnum) {
    if (navbarEnum == NavbarEnum.Landing)
      return (
        <div>
          <img
            className="logo"
            src="https://www.facebook.com/images/fb_icon_325x325.png"
          />
        </div>
      );
  }

  function ShowLogin(navbarEnum: NavbarEnum) {
    if (
      navbarEnum == NavbarEnum.Landing ||
      navbarEnum == NavbarEnum.PreRegistration
    ) {
      return (
        <Link to="/login" className="semiBold-font login-button">
          Login
        </Link>
      );
    }
  }

  function ShowUserLoggedIn() {
    if (userLoggedIn()) {
      return (
        <div className="logged-in-section">
          <p className="medium-font">
            Hello, <span>{firstName}</span>
          </p>
          <AccountMenu
            displayName={displayName}
            userProfilePicture={userProfilePicture}
          ></AccountMenu>
        </div>
      );
    }
  }

  function navigateHome() {
    if (userLoggedIn()) navigate("/feed");
    else navigate("/");
  }

  function isStringDifferentFromEmptyGuid(str: string): boolean {
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    return str !== emptyGuid;
  }

  return (
    <div
      className={
        navbarEnum == NavbarEnum.Landing
          ? "navigation-bar-wrapper"
          : "pre-registration-navigation-bar-wrapper"
      }
    >
      <nav
        className={
          navbarEnum == NavbarEnum.Landing
            ? "navigation-bar"
            : navbarEnum == NavbarEnum.PreRegistration
              ? "pre-registration-navigation-bar"
              : "default-navigation-bar"
        }
      >
        <div className="logo-section" onClick={navigateHome}>
          <label
            onClick={() => navigate("/")}
            role="button"
            className="semiBold-font"
          >
            Buddy
          </label>
          <label
            role="button"
            onClick={() => navigate("/")}
            className="semiBold-font"
          >
            Match
          </label>
        </div>
        {ContactSection(navbarEnum)}
        {LoggedInSection()}
        <div className="account-section">
          {ShowLogin(navbarEnum)}
          {CreateAccount(navbarEnum)}
          {ShowUserLoggedIn()}
        </div>
      </nav>
    </div>
  );
};

export default NavigationBar;
