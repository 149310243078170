import { Box, Container, CssBaseline, Grid, TextField, ThemeProvider, createTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UniversityOnboardingVM } from "../../models/UniversityOnboardingVM";
import OnboardingService from "../../services/onboardingService";
import "./UniOnboardingResponsiblePerson.css"

interface Props {
  setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
  onboardingModel?: UniversityOnboardingVM;
  setOnboardingModel: Dispatch<SetStateAction<UniversityOnboardingVM>>;
}

const UniOnboardingResponsiblePerson: React.FC<Props> = ({ onboardingModel, setOnboardingModel, setDisableContinueButton }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddres, setEmailAddres] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [zip, setZip] = useState("");

  const isValid = () => {
    return firstName.length > 0 && lastName.length > 0 && emailAddres.length > 0 && phone.length > 0 && country.length > 0
      && city.length > 0 && streetName.length > 0 && zip.length > 0 && streetNumber.length > 0;
  }

  useEffect(() => {
    updateOnboardingModel();
    new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
  }, [firstName, lastName, emailAddres, phone, country, city, streetName, streetNumber, zip])


  const updateOnboardingModel = () => {
    if (onboardingModel) {
      if (firstName)
        onboardingModel.first_name = firstName;
      if (lastName)
        onboardingModel.last_name = lastName;
      if (emailAddres)
        onboardingModel.email = emailAddres;
      if (phone)
        onboardingModel.phone = phone;
      if (country)
        onboardingModel.address.country = country;
      if (city)
        onboardingModel.address.city = city;
      if (streetName)
        onboardingModel.address.street_name = streetName;
      if (streetNumber)
        onboardingModel.address.street_number = streetNumber;
      if (zip)
        onboardingModel.address.zip = zip;
      setOnboardingModel(onboardingModel);
    }
  }
  useEffect(() => {
    if (onboardingModel) {
      setFirstName(onboardingModel.first_name);
      setLastName(onboardingModel.last_name);
      setEmailAddres(onboardingModel.email);
      setPhone(onboardingModel.phone);
      setCountry(onboardingModel.address.country);
      setCity(onboardingModel.address.city);
      setStreetName(onboardingModel.address.street_name);
      setStreetNumber(onboardingModel.address.street_number);
      setZip(onboardingModel.address.zip);
    }
  }, [])

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#33CC99", //this overide blue color with base green color of our app
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Montserrat Regular",
      fontSize: 11,
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p className="bold-font uni-onboarding-responsible-p">Please enter info about responsible person</p>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  onChange={e => setFirstName(e.target.value)}
                  value={firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={e => setLastName(e.target.value)}
                  value={lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={e => setEmailAddres(e.target.value)}
                  value={emailAddres}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="tel"
                  id="phone"
                  autoComplete="new-phone"
                  onChange={e => setPhone(e.target.value)}
                  value={phone}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  fullWidth
                  name="country"
                  label="Country"
                  type="text"
                  id="country"
                  autoComplete="country"
                  onChange={e => setCountry(e.target.value)}
                  value={country}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="zip"
                  label="Zip"
                  name="zip"
                  autoComplete="zip"
                  onChange={e => setZip(e.target.value)}
                  value={zip}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="city"
                  label="City"
                  type="text"
                  id="city"
                  autoComplete="city"
                  onChange={e => setCity(e.target.value)}
                  value={city}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  name="street name"
                  required
                  fullWidth
                  id="street name"
                  autoComplete="street name"
                  label="Street name"
                  onChange={e => setStreetName(e.target.value)}
                  value={streetName}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="street number"
                  label="Number"
                  name="street number"
                  autoComplete="street number"
                  onChange={e => setStreetNumber(e.target.value)}
                  value={streetNumber}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );

}

export default UniOnboardingResponsiblePerson

