import {createTheme} from "@mui/material/styles";

const greenTheme = createTheme({
    palette: {
        primary: {
            main:"#33CC99",
            contrastText: "#fff"
        },
        secondary: {
            main: '#001737',
        },
    },
});
export default greenTheme;