import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { localStorageService } from '../../../services/localStorageService';
import { InterestOptions } from '../../../models/InterestOptionsVM';
import { useTranslation } from 'react-i18next';
import { LookupTypeEnum } from '../../../enums/lookupType-enum';
import { LookupVM } from '../../../models/LookupVM';
import MultipleChoice from '../../MultipleChoice/MultipleChoice';
import LookupService from '../../../services/lookupService';
import OnboardingVM from '../../../models/OnboardingVM';
import OnboardingLookupVM from '../../../models/OnboardingLookupVM';
import OnboardingService from '../../../services/onboardingService';
import './OnboardingSubInterestsFirst.css'

interface Props {
    setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
    onboardingModel?: OnboardingVM;
    setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
}

const OnboardingSubinterestsFirst: React.FC<Props> = ({ setDisableContinueButton, onboardingModel, setOnboardingModel }) => {
    const { t } = useTranslation("");
    const [options, setOptions] = useState([] as InterestOptions[])
    const [localOnboardingModel] = useState(onboardingModel);

    async function setOptionsState() {
        const key = 'hobby_options';
        let data: LookupVM[];
        const cachedData = localStorageService.getItem(key);
        if (cachedData) {
            data = JSON.parse(cachedData);
        } else {
            const response = await new LookupService().getByLookupType(LookupTypeEnum.Hobbies);
            data = response.data;
            localStorageService.setItem(key, JSON.stringify(data));
        }

        const selectedOptions: InterestOptions[] = data.map((x) => {
            const value: InterestOptions = { selected: false, value: x };
            const match = localOnboardingModel?.hobbies?.filter((y) => y.lookup_id === x.id);
            if (match && match.length > 0)
                value.selected = true;
            return value;
        });
        setOptions(selectedOptions)
    }

    const updateOnboardingModel = () => {
        const selected: InterestOptions[] = options.filter((x) => x.selected);
        const lookups: OnboardingLookupVM[] = selected.map((x) => {
            return { lookup_id: x.value.id };
        })
        if (localOnboardingModel && selected.length > 0) {
            localOnboardingModel.hobbies = lookups;
            setOnboardingModel(localOnboardingModel);
        }
    }

    const isValid = () => {
        const selected = options.filter(x => x.selected);
        return selected.length >= 3;
    }

    useEffect(() => {
        updateOnboardingModel();
        new OnboardingService().handleContinueButtonChange(isValid, setDisableContinueButton);
    }, [options])

    useEffect(() => {
        setOptionsState();
    }, [])
    
    return (
        <div>
            <MultipleChoice options={options} header={t("HOBBIES.FREE_TIME_HOBBIES")} setOptions={setOptions}></MultipleChoice>
        </div>
    )
}

export default OnboardingSubinterestsFirst