import BaseTable from "../BaseTable/BaseTable";
import React, { useCallback, useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { SemesterItemVM } from "../../models/SemesterVM";
import { useTranslation } from "react-i18next";
import semesterService from "../../services/semesterService";
import SemesterModal from "./SemesterModal";
import "./Semesters.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UniService from "../../services/UniService";
export default function Semesters({ showAlert }: any) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState<SemesterItemVM[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.3,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 0.3,
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 0.3,
    },
    {
      field: "action",
      headerName: "Action",
      type: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<DeleteForeverIcon style={{ color: "red" }} />}
          label="Delete"
          onClick={deleteSemester(params.id)}
        />,
      ],
    },
  ];

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchSemesters = useCallback(async () => {
    try {
      setIsLoading(true);

      const universityResponse = await new UniService().getInfo();
      const response = await new semesterService().getSemesters(universityResponse.data.id);
      if (response.message === "OK") {
        setRowData(response.data.semesters);
        setRowCount(response.data.total_count);
      }
    } catch (error) {
      showAlert(t("SEMESTER.SEMESTER_FETCH_ERROR"), "error");
    } finally {
      setIsLoading(false);
    }
  }, [paginationModel.page, paginationModel.pageSize]);

  const deleteSemester = React.useCallback(
    (id: string) => async () => {
      const response = await new semesterService().deleteSemester(id);
      if (response.message === "OK") {
        showAlert(t("SEMESTER.DELETE_SUCCESS"), "success");
        await fetchSemesters();
      } else {
        showAlert(t("SEMESTER.DELETE_ERROR"), "error");
      }
    },
    [fetchSemesters, t]
  );

  useEffect(() => {
    fetchSemesters().then();
  }, [paginationModel, fetchSemesters]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99",
        contrastText: "#fff",
      },
    },
  });
  return (
    <div className="student-request-container">
      <div className="semester-container">
        <div className="semester-actions">
          <ThemeProvider theme={theme}>
            <Button
              startIcon={<AddCircleIcon />}
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => handleOpenModal()}
            >
              Add Semester
            </Button>
          </ThemeProvider>
        </div>
        <BaseTable
          columns={columns}
          rows={rowData}
          totalRowCount={rowCount}
          setPaginationModel={setPaginationModel}
          paginationModel={paginationModel}
          loading={isLoading}
        />
        <SemesterModal
          open={open}
          handleClose={handleClose}
          fetch={fetchSemesters}
          showAlert={showAlert}
        />
      </div>
    </div>
  );
}
