import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UserDetails from '../../models/UserDetails';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import TextArea from '../TextArea/TextArea';

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: .8em;
  object-fit: cover;
`;

// Reusable Modal Component
interface ReusableModalProps {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    modalTitle: string;
    modalDescription: string;
    user: UserDetails | null;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxHeight: '80vh', // Adjust this value as needed
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
    overflowY: 'auto', // Add scroll when content overflows
};

// Reusable Modal Component
function ReusableModal({
    open,
    handleOpen,
    handleClose,
    modalTitle,
    modalDescription,
    user,
}: ReusableModalProps) {
    const fallbackImageUrl = 'https://ui-avatars.com/api/?rounded=true';
    const fullName = `${user?.first_name} ${user?.last_name}`;

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ProfileImage
                        src={user?.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(fullName)}`}
                        alt=""
                    />
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalTitle}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalDescription}
                    </Typography>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Full name"
                        value={fullName}
                        fullWidth
                    />
                    <div style={{ marginTop: '16px' }} />
                    <div style={{ marginTop: '16px' }} />
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Email"
                        value={user?.email}
                        fullWidth
                    />
                    <div style={{ marginTop: '16px' }} />
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Age"
                        value={user?.age}
                        fullWidth
                    />
                    <div style={{ marginTop: '16px' }} />
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Gender"
                        value={user?.gender}
                        fullWidth
                    />
                    <div style={{ marginTop: '16px' }} />
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Phone number"
                        value={user?.phone_number}
                        fullWidth
                    />
                    {user?.involved_reason !== null && user?.involved_reason !== "" && (
                        <div style={{ marginTop: '16px' }}>
                            <TextArea nesto="Why do you want to get involved with our program?" value={user?.involved_reason} />
                        </div>
                    )}
                    {user?.benefits !== null && user?.benefits !== "" && (
                        <div style={{ marginTop: '16px' }}>
                            <TextArea nesto="What makes you a good Buddy and what can your International expect from you?" value={user?.benefits} />
                        </div>
                    )}
                    {user?.expectations !== null && user?.expectations !== "" && (
                        <div style={{ marginTop: '16px' }}>
                            <TextArea nesto="What are your expectations of the Buddy Program?" value={user?.expectations} />
                        </div>
                    )}
                </Box>
            </Modal>
        </div>
    );
}

export default ReusableModal;