import { Route, Routes } from "react-router";
import "./App.css";
import Landing from "./components/Landing/Landing";
import Login from "./components/Login/Login";
import PreRegistration from "./components/PreRegistration/PreRegistration";
import Registration from "./components/Registration/Registration";
import ContactUs from "./pages/ContactUs/ContactUs";
import UniversityLayout from "./components/Layout/UniversityLayout";
import RequireAuth from "./components/RequireAuth";
import Feed from "./components/Feed/Feed";
import Onboarding from "./pages/Onboarding/Onboarding";
import universityRoutes from "./components/Layout/UniversityRoutes";
import { map } from "lodash";
import { UserRole } from "../src/constants/userRole";
import Feedback from "./pages/Feedback/Feedback";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contacts";
import { authInterceptor } from "./http-common";
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import UserProfile from "./pages/UserProfile/UserProfile";
import UniversityInfoWithNav from "./components/University/UniversityInfoWithNav";
import UniversityOnboarding from "./pages/UniversityOnboarding/UniversityOnboarding";
import PageNotFound from "./components/PageNotFound/PageNotFound";

function App() {
  const navigate = useNavigate()
  const { setAuth } = useAuth();

  authInterceptor(setAuth, navigate);

  return (
    <Routes>
      <Route index element={<Landing />} />
      <Route path="/" element={<Landing />} />
      <Route path="/pre-registration" element={<PreRegistration />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Registration />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route element={<RequireAuth allowedRoles={[UserRole.University]} />}>
        <Route path="/" element={<UniversityLayout />}>
          {map(universityRoutes, ({ path, element }) => (
            <Route path={path} element={element} key={path} />
          ))}
        </Route>
      </Route>

      <Route path="/onboarding" element={<Onboarding />}></Route>
      <Route path="/feed" element={<Feed />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/user-profile/:id" element={<UserProfile />} />
      <Route path="/user-profile" element={<UserProfile />} />
      <Route path="/university-info" element={<UniversityInfoWithNav />} />
      <Route path="/university-onboarding" element={<UniversityOnboarding />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
