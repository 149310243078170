import { useEffect } from "react";
import "./Feed.css";
import {
  LinearProgress,
  Button,
  ThemeProvider,
  createTheme,
  Snackbar,
  Alert,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import { useState } from "react";
import { UserRole } from "../../constants/userRole";
import { useNavigate } from "react-router";
import { NavbarEnum } from "../../enums/navbar-enum";
import NavigationBar from "../NavigationBar/NavigationBar";
import ConnectionService from "../../services/ConnectionService";
import { ConnectionStatusEnum } from "../../enums/connectionStatus-enum";
import { localStorageService } from "../../services/localStorageService";
import { OnboardingTypeEnum } from "../../enums/onboardingType-enum";

interface AuthData {
  roles: string[];
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#001737",
    },
    secondary: {
      main: "#FD6A3B",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          fontFamily: "Montserrat Medium",
          fontSize: "12px",
          fontStyle: "normal",
          textTransform: "none",
          color: "#FFFFFF",
        },
      },
    },
  },
});
function Feed() {
  const { t } = useTranslation();
  const [feedData, setFeedData] = useState({
    person_type: "",
    profile_percentage: "",
    university_image_url: "",
    university_name: "",
  });
  const [connectionStatus, setConnectionStatus] = useState(ConnectionStatusEnum.NotRequested);
  const [showMatchRequestedPopup, setShowMatchRequestedPopup] = useState(false);
  const navigate = useNavigate();
  const navigateToOnboarding = () => {
    const key = "auth"
    const cachedData = localStorageService.getItem(key);
    if (cachedData) {
      var data = JSON.parse(cachedData) as AuthData;
      navigate("/onboarding", { state: { role: data.roles[0] === "Student" ? OnboardingTypeEnum.Student : OnboardingTypeEnum.Buddy } });
    }
  }
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await new UserService().getUser();
        const data = response.data;
        setFeedData({
          profile_percentage: data.profile_percentage,
          university_image_url: data.university_image_url,
          university_name: data.university_name,
          person_type: data.role === UserRole.Student ? "buddies" : "students",
        });
        setConnectionStatus(data.connection_status);
      } catch (error) { }
    };
    fetchUser().then();
  }, []);

  const profilePercentage = Number(
    feedData.profile_percentage.replace("%", "")
  );

  const requestMatchAction = async () => {
    try {
      const res = await new ConnectionService().requestMatch();
      if (res.error_code === 0) {
        setConnectionStatus(ConnectionStatusEnum.Requested)
        setShowMatchRequestedPopup(true);
      }
    } catch (error) {
    }
  }

  function disableRequestMatchButton(): boolean {
    const key = "auth"
    const cachedData = localStorageService.getItem(key);
    if (cachedData) {
      var data = JSON.parse(cachedData) as AuthData;
      var role = data.roles[0] === "Student" ? OnboardingTypeEnum.Student : OnboardingTypeEnum.Buddy;
      if (role === OnboardingTypeEnum.Buddy) {
        return profilePercentage < 63;
      } else {
        return profilePercentage < 60;
      }
    }

    return false;
  }

  function displayRequestMatch() {
    if (connectionStatus === ConnectionStatusEnum.NotRequested)
      return (
        <div className="university-content-wrapper">
          <h4>{feedData.university_name}</h4>
          <div className="get-started-div">{t("FEED.GET_STARTED")}</div>
          <div className="university-text">
            {t("FEED.UNIVERSITY_LABEL_FOR_BUDDY", {
              pearsonType: feedData.person_type,
            })}
          </div>
          <Button
            variant={"contained"}
            className="semiBold-font"
            color="secondary"
            disabled={disableRequestMatchButton()}
            onClick={requestMatchAction}
          >
            {t("FEED.REQUEST_BUTTON")}
          </Button>
          <p className="feed-disclaimer bold-medium">{t("FEED.DISCLAIMER")}</p>
        </div>
      )
  }

  const displayRequestSent = () => {
    if (connectionStatus === ConnectionStatusEnum.Requested)
      return (
        < div className="university-content-wrapper" >
          <h4>{feedData.university_name}</h4>
          <div className="requested-match-div bold-font">{t("FEED.MATCH_REQUESTED")}</div>
          <div className="university-text">
            {t("FEED.MATCH_REQUESTED_LABEL", {
              pearsonType: feedData.person_type,
            })}
          </div>
        </div >
      )
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowMatchRequestedPopup(false);
    window.history.replaceState({}, document.title);
  };

  return (
    <div className="feed-container">
      <NavigationBar navbarEnum={NavbarEnum.Feed}></NavigationBar>
      <ThemeProvider theme={theme}>
        <div className="feed-card mar-bott-m">
          <div className="feed-card-content">
            <div>
              <h1 className="feed-percentage">{feedData.profile_percentage}</h1>
              <div>{t("FEED.PERCENTAGE_LABEL")}</div>
            </div>
            <div className="progress-bar-wrapper mar-bott-m">
              <LinearProgress
                variant="determinate"
                value={profilePercentage}
                sx={{
                  borderRadius: 5,
                  color: "#FFB800",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#FFB800",
                  },
                  backgroundColor: "#D9D9D9",
                  width: "70%",
                  height: 20,
                }}
                className="mar-bott-m progress-bar"
              />
              <div className="mar-bott-sml">
                <h5>
                  {t("FEED.COMPLETE_LABEL_FOR_BUDDY", {
                    pearsonType: feedData.person_type,
                  })}
                </h5>
              </div>
              <div className="feed-title-1 mar-bott-m">
                {t("FEED.SEARCH_LABEL", { pearsonType: feedData.person_type })}
              </div>
              {feedData.profile_percentage !== '100%' && <Button variant={"contained"} onClick={navigateToOnboarding}>{t("FEED.COMPLETE_BUTTON")}</Button>}
            </div>
          </div>
        </div>
        <div className="university-card">
          <div className="feed-card-content">
            {displayRequestMatch()}
            {displayRequestSent()}
            <div className="university-content-wrapper">
              <img
                src={feedData.university_image_url}
                width={300}
                className="university-image"
                alt={feedData.university_image_url}
              />
            </div>
          </div>
        </div>
      </ThemeProvider>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={showMatchRequestedPopup}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Match requested!
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Feed;
