import React from 'react'
import styled from 'styled-components'
import { ReactComponent as YourSvg } from './7740133_3737256.svg';
import { Button, Link } from '@mui/material';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const NotFoundImage = styled.div`
    width: 25%;
`

const NotFoundTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1em;
`

const NotFoundDescription = styled.div`
    font-size: 1.1rem;
`

export default function PageNotFound() {
    return (
        <Wrapper>
            <NotFoundImage>
                <YourSvg />
            </NotFoundImage>
            <NotFoundTitle>
                404 - PAGE NOT FOUND
            </NotFoundTitle>
            <NotFoundDescription>
                The page you are looking for might have been removed
            </NotFoundDescription>
            <NotFoundDescription>
                had its name changed or is temporarily unavailable.
            </NotFoundDescription>
        </Wrapper>
    )
}
