import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import "./ContactUs.css";
import InputLabel from "@mui/material/InputLabel";
import greenTheme from "../../utils/greenTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTranslation } from "react-i18next";
import UniService from "../../services/UniService";
import { CreateUniversityVM } from "../../models/CreateUniversityVM";
export default function ContactUs() {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState<CreateUniversityVM>({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({ ...prevState, [name]: value }));
  };
  const sendEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await new UniService().create(contactData);
    } catch (error) {}
  };

  return (
    <div className="contact-us-page-container semiBold-font">
      <div className="contact-us-blue-container" />
      <div className="contact-us-container col-7">
        <img
          alt="contact us"
          className="contact-us-image"
          src={require("../../assets/images/contact-us-pic.jpeg")}
        />
        <form className="contact-us-form" onSubmit={sendEmail}>
          <ThemeProvider theme={greenTheme}>
            <h1 className="align-left color-blue">{t("CONTACT_US.TITLE_1")}</h1>
            <p className="align-left color-blue margin-btm-m">
              {t("CONTACT_US.TITLE_2")}{" "}
              {<span className="color-green">{t("APP_NAME")}</span>}{" "}
              {t("CONTACT_US.TITLE_3")}
            </p>
            <div className="row margin margin-btm-m">
              <div className="col no-padding margin-right">
                <InputLabel
                  htmlFor="first-name"
                  className="align-left margin-btm-sml"
                >
                  {t("CONTACT_US.FORM.FIRST_NAME")}
                </InputLabel>
                <CustomTextField
                  title={"CONTACT_US.FORM.FIRST_NAME"}
                  field={"first_name"}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col no-padding">
                <InputLabel
                  htmlFor="lastName"
                  className="align-left margin-btm-sml"
                >
                  {t("CONTACT_US.FORM.LAST_NAME")}
                </InputLabel>
                <CustomTextField
                  title={"CONTACT_US.FORM.LAST_NAME"}
                  field={"last_name"}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row margin margin-btm-m">
              <InputLabel htmlFor="email" className="align-left margin-btm-sml">
                <DraftsIcon /> {t("CONTACT_US.FORM.EMAIL")}
              </InputLabel>
              <CustomTextField
                title={"CONTACT_US.FORM.EMAIL_LABEL"}
                field={"email"}
                onChange={handleInputChange}
              />
            </div>
            <div className="contact-us-submit-button">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                {t("CONTACT_US.FORM.SEND_BUTTON")}
              </Button>
            </div>
          </ThemeProvider>
        </form>
      </div>
    </div>
  );
}
interface CustomTextFieldProps {
  title: string;
  field: string;
  onChange: any;
}

const CustomTextField = (props: CustomTextFieldProps) => {
  const { t } = useTranslation();
  const { title, field, onChange } = props;
  return (
    <TextField
      size="small"
      label={t(title)}
      id={field}
      name={field}
      onChange={onChange}
    />
  );
};
