/* eslint-disable */
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import UserRegisterVM from "../../models/UserRegisterVM";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import NavigationBar from "../NavigationBar/NavigationBar";
import UniService from "../../services/UniService";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { UniversityVM } from "../../models/UniversityVM";
import { NavbarEnum } from "../../enums/navbar-enum";
import { Formik } from "formik";
import UserService from "../../services/UserService";
import "./Registration.css";

export default function Registration() {
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [universities, setUniversities] = useState([] as UniversityVM[]);
  const { state } = useLocation();
  const { userType } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (universities.length === 0) {
      SetUniversitiesState();
    }
  }, []);

  async function SetUniversitiesState() {
    let response = await new UniService().getAll();
    let data = response.data;
    let filteredData = data.filter(x => !x.name.includes('zzz'));
    setUniversities(filteredData);
  }

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorPopup(false);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99", //this overide blue color with base green color of our app
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Montserrat Regular",
      fontSize: 11,
    },
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        universityId: "",
      }}
      validationSchema={object({
        email: string()
          .required("Please enter your email")
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "Please enter valid email format"
          ),
        password: string()
          .required("Please enter your password")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        firstName: string()
          .required("Please enter your first name")
          .min(2, "First name should have more than 2 letters")
          .matches(
            /^[aA-zZ\s]+$/,
            "Only alphabets are allowed for this field "
          ),
        lastName: string()
          .required("Please enter your last name")
          .min(2, "Last name should have more than 2 letters")
          .matches(
            /^[aA-zZ\s]+$/,
            "Only alphabets are allowed for this field "
          ),
        universityId: string().required("Please select university"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const request: UserRegisterVM = {
          email: values.email,
          password: values.password,
          first_name: values.firstName,
          last_name: values.lastName,
          user_type: userType,
          university_id: values.universityId,
        };

        const response = await new UserService().Register(request);
        if (response.message === "OK") {
          navigate("/login", { state: { successfulRegistration: true } });
        } else {
          setShowErrorPopup(true);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <div>
          <NavigationBar navbarEnum={NavbarEnum.Login}></NavigationBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <Box sx={{ marginTop: 20 }}>
                <p className="bold-font h3 text-start mb-0">
                  Create new account.
                </p>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <p className="medium-font mb-0">Already have an account?</p>
                  <Link
                    href="/login"
                    variant="body2"
                    sx={{
                      ml: 1,
                      fontWeight: "bold",
                      fontSize: 14,
                      textDecoration: "none",
                    }}
                  >
                    Log in
                  </Link>
                </Box>
              </Box>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstName"
                  autoComplete="family-name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName != null && touched.firstName}
                  helperText={
                    errors.firstName != null && touched.firstName
                      ? errors.firstName
                      : ""
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName != null && touched.lastName}
                  helperText={
                    errors.lastName != null && touched.lastName
                      ? errors.lastName
                      : ""
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email != null && touched.email}
                  helperText={
                    errors.email != null && touched.email ? errors.email : ""
                  }
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password != null && touched.password}
                  helperText={
                    errors.password != null && touched.password
                      ? errors.password
                      : ""
                  }
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel
                    error={errors.universityId != null && touched.universityId}
                    id="demo-simple-select-label"
                  >
                    Universities
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.universityId}
                    error={errors.universityId != null && touched.universityId}
                    label="Universities"
                    name="universityId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {universities.map((element) => {
                      return (
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, fontSize: 16, textTransform: "none" }}
                >
                  Create account
                </Button>
              </Box>
            </ThemeProvider>
          </Container>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={showErrorPopup}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                One or more validation errors occurred!
              </Alert>
            </Snackbar>
          </Stack>
        </div>
      )}
    </Formik>
  );
}
