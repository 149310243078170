import {
  GridView,
  Person,
  GroupAdd,
  SupervisorAccount,
  School,
  Settings,
} from "@mui/icons-material";

const sidebarItems = [
  {
    title: "SIDEBAR.DASHBOARD",
    icon: <GridView />,
    route: "/dashboard",
  },
  {
    title: "SIDEBAR.BUDDY_REQUESTS",
    icon: <GroupAdd />,
    route: "/buddy-requests",
  },
  {
    title: "SIDEBAR.STUDENT_REQUESTS",
    icon: <School />,
    route: "/student-requests",
  },
  {
    title: "SIDEBAR.BUDDIES",
    icon: <Person />,
    route: "/buddies",
  },
  {
    title: "SIDEBAR.STUDENTS",
    icon: <School />,
    route: "/students",
  },
  {
    title: "SIDEBAR.MATCHING_PROCESS",
    icon: <SupervisorAccount />,
    route: "/matchmaking",
  },
  {
    title: "SIDEBAR.MATCHES",
    icon: <SupervisorAccount />,
    route: "/matches",
  },
  {
    title: "SIDEBAR.UNIVERSITY_SETTINGS",
    icon: <Settings />,
    route: "/university",
  },
];
export default sidebarItems;
