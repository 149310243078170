import { UniversityOnboardingStepsEnum } from '../../enums/universityOnboardingSteps-enum'
import { UniversityOnboardingVM } from '../../models/UniversityOnboardingVM'
import { BuddyOnboardingStepsEnum, StudentOnboardingStepsEnum } from '../../enums/onboardingSteps-enum'
import { OnboardingTypeEnum } from '../../enums/onboardingType-enum'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import OnboardingVM from '../../models/OnboardingVM'
import OnboardingService from '../../services/onboardingService'
import './OnboardingNavButtons.css'
import { Alert, Snackbar, Stack } from '@mui/material'
import { useState } from 'react'

interface Props {
    setActiveStep: any
    activeStep: BuddyOnboardingStepsEnum | StudentOnboardingStepsEnum | UniversityOnboardingStepsEnum
    disableContinueButton: boolean
    type: OnboardingTypeEnum
    onboardingModel?: OnboardingVM
    universityOnboardingModel?: UniversityOnboardingVM
}

const OnboardingNavButtons: React.FC<Props> = ({ setActiveStep, activeStep, disableContinueButton, type, onboardingModel, universityOnboardingModel }) => {
    const lastStep = type === OnboardingTypeEnum.Buddy ? BuddyOnboardingStepsEnum.BuddyHelp : type === OnboardingTypeEnum.Student ? StudentOnboardingStepsEnum.BuddyHelp : UniversityOnboardingStepsEnum.About;
    const firstStep = type === OnboardingTypeEnum.Buddy ? BuddyOnboardingStepsEnum.Personal : type === OnboardingTypeEnum.Student ? StudentOnboardingStepsEnum.Personal : UniversityOnboardingStepsEnum.ResponsiblePerson;
    const [showCompletedAlert, setShowCompletedAlert] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation("");

    const continueAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!disableContinueButton && activeStep < lastStep) {
            setActiveStep(++activeStep)
        }
        saveOnboardingData();
    }

    const saveOnboardingData = () => {
        const previousStep = activeStep - 1;
        if (type === OnboardingTypeEnum.Buddy) {
            if (onboardingModel) {
                switch (previousStep) {
                    case BuddyOnboardingStepsEnum.Personal:
                    case BuddyOnboardingStepsEnum.SubPersonalFirst:
                    case BuddyOnboardingStepsEnum.SubPersonalSecond:
                        new OnboardingService().updatePersonal(onboardingModel);
                        break;
                    case BuddyOnboardingStepsEnum.Education:
                    case BuddyOnboardingStepsEnum.SubEducationFirst:
                        new OnboardingService().updateEducation(onboardingModel);
                        break;
                    case BuddyOnboardingStepsEnum.Motivation:
                        new OnboardingService().updateMotivation(onboardingModel);
                        break;
                    case BuddyOnboardingStepsEnum.ProfilePicture:
                        new OnboardingService().uploadProfilePicture(onboardingModel, onboardingModel.imageToUpload);
                        break;
                    case BuddyOnboardingStepsEnum.Interests:
                    case BuddyOnboardingStepsEnum.SubInterestsFirst:
                    case BuddyOnboardingStepsEnum.BuddyHelp:
                        new OnboardingService().updateInterests(onboardingModel);
                        break;
                }
            }
        } else if (type === OnboardingTypeEnum.Student) {
            if (onboardingModel) {
                switch (previousStep) {
                    case StudentOnboardingStepsEnum.Personal:
                    case StudentOnboardingStepsEnum.SubPersonalFirst:
                    case StudentOnboardingStepsEnum.SubPersonalSecond:
                        new OnboardingService().updatePersonal(onboardingModel);
                        break;
                    case StudentOnboardingStepsEnum.Education:
                    case StudentOnboardingStepsEnum.SubEducationFirst:
                        new OnboardingService().updateEducation(onboardingModel);
                        break;
                    case StudentOnboardingStepsEnum.ProfilePicture:
                        new OnboardingService().uploadProfilePicture(onboardingModel, onboardingModel.imageToUpload);
                        break;
                    case StudentOnboardingStepsEnum.Interests:
                    case StudentOnboardingStepsEnum.SubInterestsFirst:
                    case StudentOnboardingStepsEnum.BuddyHelp:
                        new OnboardingService().updateInterests(onboardingModel);
                        break;
                }
            }
        }
    }

    const backAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (activeStep > firstStep) {
            setActiveStep(--activeStep)
        }
    }

    const completeUniOnboarding = async () => {
        try {
            if (universityOnboardingModel) {
                const response = await new OnboardingService().UniversityOnboarding(universityOnboardingModel);
                if (response.validation_errors?.length > 0) {
                    alert(response.validation_errors[0])
                }
                else {

                    if (universityOnboardingModel.image)
                        await new OnboardingService().UniversityOnboardingProfilePicture(universityOnboardingModel.image);
                    setShowCompletedAlert(true);
                    const delayInMilliseconds = 1500; // 1.5 second of timeout just for user to see successful alert message
                    setTimeout(() => navigate("/dashboard"), delayInMilliseconds);
                }
            }
        }
        catch (error) {
            const errorMessage = (error as Error).stack;
            if (errorMessage)
                alert(errorMessage)
        }

    }

    const completeStudentBuddyOnboarding = async () => {
        if (onboardingModel)
            await new OnboardingService().updateInterests(onboardingModel);

        const delayInMilliseconds = 1000; // 1 second
        setTimeout(() => navigate("/feed"), delayInMilliseconds);
        const button = document.getElementById("onboardingButton") as HTMLButtonElement;
        button.textContent = "Completing..."
    }

    const completeOnboarding = (e: React.MouseEvent<HTMLButtonElement>) => {
        ((type === OnboardingTypeEnum.Buddy) || (type === OnboardingTypeEnum.Student)) ? completeStudentBuddyOnboarding() : completeUniOnboarding();
    }

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setShowCompletedAlert(false);
    };

    const skipAction = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (activeStep < lastStep) {
            setActiveStep(++activeStep)
        } else {
            navigate("/feed")
        }
    }

    function showSkipButton() {
        if (type === OnboardingTypeEnum.Buddy) {
            if ((activeStep === BuddyOnboardingStepsEnum.ProfilePicture || activeStep === BuddyOnboardingStepsEnum.Interests || activeStep === BuddyOnboardingStepsEnum.SubInterestsFirst || activeStep === BuddyOnboardingStepsEnum.BuddyHelp))
                return (
                    <button className="skip-button" onClick={skipAction}>Skip</button>
                )
        } else if (type === OnboardingTypeEnum.Student) {
            if ((activeStep === StudentOnboardingStepsEnum.ProfilePicture || activeStep === StudentOnboardingStepsEnum.Interests || activeStep === StudentOnboardingStepsEnum.SubInterestsFirst || activeStep === StudentOnboardingStepsEnum.BuddyHelp))
                return (
                    <button className="skip-button" onClick={skipAction}>Skip</button>
                )
        }
    }
    return (
        <div className="main-wrapper">
            <div className="nav-buttons-wrapper semiBold-font">
                {activeStep != firstStep ? <button onClick={backAction} className="back-button">{t("ONBOARDING_NAV_BUTTONS.BACK")}</button> : null}
                <button id='onboardingButton' disabled={disableContinueButton} onClick={activeStep == lastStep ? completeOnboarding : continueAction} className={disableContinueButton ? "continue-button-disabled" : "continue-button"}>
                    {activeStep == lastStep ? t("ONBOARDING_NAV_BUTTONS.COMPLETE") : t("ONBOARDING_NAV_BUTTONS.CONTINUE")}</button>
                {showSkipButton()}
            </div>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={showCompletedAlert}
                    autoHideDuration={1500}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        Enrollment complete!
                    </Alert>
                </Snackbar>
            </Stack>
        </div>

    );
}

export default OnboardingNavButtons