import React, { useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import greenTheme from '../../utils/greenTheme';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import './Sidebar.css';

import sidebarItems from './sidebarItems';

export default function Sidebar() {
  const { t } = useTranslation();
  const location = useLocation();

  const isItemActive = (route: any) => {
    return location.pathname === route;
  };

  return (
    <List>
      {map(sidebarItems, ({ title, icon, route }, index) => (
        <ListItem
          className="sidebar-item"
          component={Link}
          key={index}
          disablePadding
          to={route}
          selected={isItemActive(route)}
        >
          <ListItemButton className="sidebar-button">
            <ListItemIcon className="sidebar-item-icon">{icon}</ListItemIcon>
            <ThemeProvider theme={greenTheme}>
              <ListItemText primary={t(title)} className="sidebar-item-text" />
            </ThemeProvider>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
