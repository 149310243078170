import BaseTable from "../BaseTable/BaseTable";
import "./StudentRequest.css";
import { useTranslation } from "react-i18next";
import SearchInput from "../SearchInput/SearchInput";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import UserSearchRequestModel from "../../models/UserSearchRequestModel";
import UserResponseModel from "../../models/UserReponseModel";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UniService from "../../services/UniService";
import useAlert from "../Alert/useAlert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserDetails from "../../models/UserDetails";
import ViewModal from "../Modals/ViewModal";
import CancelIcon from '@mui/icons-material/Cancel';
import { ToastContainer, toast } from "react-toastify";

export default function StudentRequest() {
  const { showAlert, AlertComponent } = useAlert();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UserResponseModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [searchText, setSearch] = useState<string>("");

  const handleUser = (params: any) => {
    setSelectedUser(params.row)
    setOpenModal(true);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const studentRequestColumns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 0.9,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 0.9,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.9,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.9,
      type: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<CheckCircleIcon color="success" />}
          label="Approve"
          onClick={approveUser(params.id, params.row.first_name, params.row.last_name)}
        />,
        <GridActionsCellItem
          icon={<CancelIcon color="error" />}
          label="Reject"
          onClick={rejectUser(params.id, params.row.first_name, params.row.last_name)}
        />,
        <GridActionsCellItem
          icon={<VisibilityIcon color="warning" />}
          label="Approve"
          onClick={() => { handleUser(params) }}
        />
      ],
    },
  ];
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const request: UserSearchRequestModel = {
        role: 1,
        skip:
          paginationModel.page > 0
            ? paginationModel.page * paginationModel.pageSize
            : 0,
        take: paginationModel.pageSize,
        search: searchText,
        status: 1,
        connection_status: 1,
        buddy_status: 0,
        student_status: 0
      };

      const response = await new UserService().getUsersPerUniversity(request);
      if (response.message === "OK") {
        setRowData(response.data.users);
        setRowCount(response.data.total_count);
      }
    } catch (error) {
      showAlert(t("STUDENT_REQUEST.USER_FETCH_ERROR"), "error");
    } finally {
      setIsLoading(false);
    }
  }, [searchText, paginationModel.page, paginationModel.pageSize]);

  const rejectUser = React.useCallback(
    (id: string, first_name: string, last_name: string) => async () => {
      if (!window.confirm("You want to reject " + first_name + " " + last_name + " ?")) {
        return;
      }

      const response = await new UniService().rejectUser(id);
      if (response.message === "OK") {
        toast.success('Student rejected!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        await fetchUsers();
      } else {
        toast.error('Something went wrong!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
    [fetchUsers, t]
  );

  const approveUser = React.useCallback(
    (id: string, first_name: string, last_name: string) => async () => {
      if (!window.confirm("You want to approve " + first_name + " " + last_name + " ?")) {
        return;
      }
      const response = await new UniService().approveUser(id);
      if (response.message === "OK") {
        toast.success(t("STUDENT_REQUEST.APPROVE_SUCCESS_MESSAGE", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }));
        await fetchUsers();
      } else {
        toast.error(t("STUDENT_REQUEST.APPROVE_ERROR_MESSAGE"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
    [fetchUsers, t]
  );

  useEffect(() => {
    fetchUsers().then();
  }, [paginationModel, searchText, fetchUsers]);

  return (
    <div className="student-request-container">
      <ToastContainer />

      <ViewModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        modalTitle=""
        modalDescription=""
        user={selectedUser}
      />
      {AlertComponent}
      <div className="student-request-title">
        {/* <h2>{t("STUDENT_REQUEST.TITLE")}</h2> */}
      </div>
      <div>
        <div className="student-request-search">
          <SearchInput
            handleSearch={handleSearch}
            placeholder={t("STUDENT_REQUEST.SEARCH_PLACEHOLDER")}
          />
        </div>
        <div className="student-request-table">
          <BaseTable
            columns={studentRequestColumns}
            rows={rowData}
            totalRowCount={rowCount}
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
