import React, { useState } from "react";
import styled from "styled-components";
import UserResponseModel from "../../models/UserReponseModel";
import { Tooltip } from "@mui/material";

const MainWrapper = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em;
  width: 40%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: 0px 2px 2px gray;
`;

const UserCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const UserCard = styled.div`
  padding: 0 2em 0 2em;
  text-align: center;
  width: 250px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: cover;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Font = styled.div`
  font-size: 18px;
  color: #001737;
`;

const Title = styled.div`
  color: grey;
  margin-bottom: 10px;
`;

const StudentWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 50%;
  align-items: center;
`;

const ProfileImageWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const Item = styled.div`
  margin: 0em 0.3em;
`;
export default function MatchItem({
  buddies,
  students,
}: {
  buddies: UserResponseModel[];
  students: UserResponseModel[];
}) {
  const [visibleIndexBuddy, setVisibleIndexBuddy] = useState(0);
  const [visibleIndexStudent, setVisibleIndexStudent] = useState(0);
  const buddyNames = buddies.map(
    (buddy) => `${buddy.first_name} ${buddy.last_name}`
  );
  const studentNames = students.map(
    (student) => `${student.first_name} ${student.last_name}`
  );
  const handleScrollBuddy = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    const itemWidth = 85; // adjust this value to match the width of the Item component
    const newIndex = Math.round(scrollLeft / itemWidth);
    setVisibleIndexBuddy(newIndex);
  };

  const handleScrollStudent = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    const itemWidth = 85; // adjust this value to match the width of the Item component
    const newIndex = Math.round(scrollLeft / itemWidth);
    setVisibleIndexStudent(newIndex);
  };

  const getInfo = (user: UserResponseModel) => {
    const message = `Name: ${user.name}\nEmail: ${user.email}\nGender: ${user.gender
      }\n Study Type: ${user.study_type}\nDepartment: ${user.department?.name ?? "-"
      }`;
    return message;
  };

  const fallbackImageUrl = 'https://ui-avatars.com/api/?rounded=true';

  return (
    <MainWrapper>
      <UserCardsWrapper>
        <UserCard>
          <Title>BUDDIES ({buddies.length})</Title>
          <StudentWrapper onScroll={handleScrollBuddy}>
            {buddies.map((buddy) => (
              <Tooltip
                key={buddy.id}
                placement="top"
                title={
                  <div style={{ whiteSpace: "pre-line" }}>{getInfo(buddy)}</div>
                }
              >
                <Item key={buddy.id}>
                  <ProfileImage
                    height={"5em"}
                    width={"5em"}
                    src={buddy.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(buddy.first_name + ' ' + buddy.last_name)}&font-size=0.33&background=001737&color=fff`}
                  />
                </Item>
              </Tooltip>
            ))}
          </StudentWrapper>
          <Font>{buddyNames[visibleIndexBuddy]}</Font>
        </UserCard>

        <UserCard>
          <Title>STUDENT ({students.length})</Title>
          <StudentWrapper onScroll={handleScrollStudent}>
            {students.map((student) => (
              <Tooltip
                key={student.id}
                placement="top"
                title={
                  <div style={{ whiteSpace: "pre-line" }}>{getInfo(student)}</div>
                }
              >
                <Item key={student.id}>
                  <ProfileImage
                    height={"5em"}
                    width={"5em"}
                    src={student.image ?? `${fallbackImageUrl}&name=${encodeURIComponent(student.first_name + ' ' + student.last_name)}&font-size=0.33&background=33CC99&color=fff`}
                  />
                </Item>
              </Tooltip>
            ))}
          </StudentWrapper>
          <Font>{studentNames[visibleIndexStudent]}</Font>
        </UserCard>
      </UserCardsWrapper>
    </MainWrapper>
  );
}
