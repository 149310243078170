import ApiService, { ApiResponse } from "./ApiService";
import UserResponseModel from "../models/UserReponseModel";
import UserRegisterVM from "../models/UserRegisterVM";
import UserSearchRequestModel from "../models/UserSearchRequestModel";
import UserSearchResponseModel from "../models/UserSearchResponseModel";
import { UserProfileVM } from "../models/UserProfileVM";
class UserService extends ApiService {
  constructor() {
    super("user");
  }
  public async getUser(): Promise<ApiResponse<UserResponseModel>> {
    return this.get<UserResponseModel>("/info");
  }
  public async Register(UserRegisterVM: UserRegisterVM) {
    return this.post<UserRegisterVM, null>("/create", UserRegisterVM);
  }
  public async getUsersPerUniversity(
    userSearchRequestModel: UserSearchRequestModel
  ) {
    return this.post<UserSearchRequestModel, UserSearchResponseModel>(
      "/search",
      userSearchRequestModel
    );
  }
  public async getUserProfile(userId: string){
    return this.get<UserProfileVM>(`/profile/${userId}`);
  }
}
export default UserService;
