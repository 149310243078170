import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import UserLoginVM from "../../models/UserLoginVM";
import NavigationBar from "../NavigationBar/NavigationBar";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import UserService from "../../services/UserService";
import UserResponseModel from "../../models/UserReponseModel";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { NavbarEnum } from "../../enums/navbar-enum";
import { useState } from "react";
import { AuthService } from "../../services/authService";
import { Formik } from "formik";
import { UserRole } from "../../constants/userRole";
import { localStorageService } from "../../services/localStorageService";
import "./Login.css";
import useAuth from "../../hooks/useAuth";
export default function Login() {
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { state } = useLocation();
  const { successfulRegistration } = state || {}; //state which is sent from registration page when user is registred. When flag is true, show success popup when entering login page
  const [showSuccessfulRegistrationPopup, setShowSuccessfulRegistrationPopup] =
    useState(successfulRegistration);
  const navigate = useNavigate();
  const auth = useAuth();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const checkNavigateRoute = async (role: string) => {
    const key = "user_info";
    const cachedData = localStorageService.getItem(key);
    let data;
    if (cachedData) {
      data = JSON.parse(cachedData) as UserResponseModel;
    } else {
      const response = await new UserService().getUser();
      data = response.data;
      localStorageService.setItem(key, JSON.stringify(data));
    }
    if (role === UserRole.University) {
      if (data.university_onboarding)
        navigate("/dashboard");
      else
        navigate("/university-onboarding")
    } else
      navigate("/feed/");
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorPopup(false);
    setShowSuccessfulRegistrationPopup(false);
    window.history.replaceState({}, document.title);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99", //this overide blue color with base green color of our app
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Montserrat Regular",
      fontSize: 11,
    },
  });

  return (
    <div>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={object({
          email: string().required("Please enter your email"),
          password: string().required("Please enter your password"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const request: UserLoginVM = {
              email: values.email,
              password: values.password,
              remember_me: rememberMe
            };

            const response = await new AuthService().Login(request);
            if (response.message === "OK") {
              const role = response.role;
              auth.setAuth({ roles: [role] });
              //redirect to /Home
              await checkNavigateRoute(role);
            } else {
              setErrorMessage("Invalid username or password!");
              setShowErrorPopup(true);
            }

            setSubmitting(false);
          } catch (error) {
            const errorMessage = (error as Error).stack;
            if (errorMessage)
              setErrorMessage("Invalid username or password!");
            setShowErrorPopup(true);
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div>
            <NavigationBar navbarEnum={NavbarEnum.Login}></NavigationBar>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box sx={{ marginTop: 20 }}>
                <p className="bold-font h3 text-start mb-0">Welcome back.</p>
                <p className="medium-font text-start">Login to your account</p>
              </Box>
              <ThemeProvider theme={theme}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    // autoFocus
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email != null && touched.email}
                    helperText={
                      errors.email != null && touched.email ? errors.email : ""
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password != null && touched.password}
                    helperText={
                      errors.password != null && touched.password
                        ? errors.password
                        : ""
                    }
                  />
                  <Box sx={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={<Checkbox value={rememberMe} onClick={() => { setRememberMe(!rememberMe) }} color="primary" />}
                      label="Keep me logged in"
                    />
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontSize: 16, textTransform: "none" }}
                  >
                    Log in
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="p">
                      Don't have an account?
                    </Typography>
                    <Link
                      onClick={() => navigate("/pre-registration")}
                      href="#"
                      variant="body2"
                      sx={{
                        ml: 1,
                        fontWeight: "bold",
                        fontSize: 13,
                        textDecoration: "none",
                      }}
                    >
                      Register now
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <Link
                      href="#"
                      variant="body2"
                      sx={{
                        ml: 1,
                        fontWeight: "bold",
                        fontSize: 13,
                        textDecoration: "none",
                      }}
                    >
                      Forgot password
                    </Link>
                  </Box>
                </Box>
              </ThemeProvider>
            </Container>
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Snackbar
                open={showErrorPopup}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={showSuccessfulRegistrationPopup}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Registration completed successfully!
                </Alert>
              </Snackbar>
            </Stack>
          </div>
        )}
      </Formik>
    </div>
  );
}
