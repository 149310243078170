import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./Feedback.css";
import InputLabel from "@mui/material/InputLabel";
import greenTheme from "../../utils/greenTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTranslation } from "react-i18next";
import UniService from "../../services/UniService";
import { FeedbackVM } from "../../models/FeedbackVM";
import feedbackImage from "../../assets/images/feedback-pic.jpeg";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { NavbarEnum } from '../../enums/navbar-enum';
export default function Feedback() {
  const { t } = useTranslation();
  const [contactData, setContactData] = useState<FeedbackVM>({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({ ...prevState, [name]: value }));
  };
  const sendEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await new UniService().create(contactData);
    } catch (error) { }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#33CC99",
        contrastText: "#fff"
      },
    },
    typography: {
      "fontFamily": "Montserrat Regular",
      "fontSize": 12
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <NavigationBar navbarEnum={NavbarEnum.Landing} />
        <div className="feedback-page-container semiBold-font">
          <div className="feedback-container col-7">
            <img
              alt="contact us"
              className="feedback-image"
              src={feedbackImage}
            />
            <form className="feedback-form" onSubmit={sendEmail}>
              <h1 className="align-left color-blue">{t("FEEDBACK.TITLE_1")}</h1>
              <p className="align-left color-blue margin-btm-m">
                {t("FEEDBACK.TITLE_2")}
              </p>
              <div className="row margin margin-btm-m">
                <div className="col no-padding margin-right">
                  <InputLabel
                    htmlFor="first-name"
                    className="align-left margin-btm-sml"
                  >
                    {t("FEEDBACK.FORM.FIRST_NAME")}
                  </InputLabel>
                  <CustomTextField
                    title={"FEEDBACK.FORM.FIRST_NAME"}
                    field={"firstName"}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col no-padding">
                  <InputLabel
                    htmlFor="lastName"
                    className="align-left margin-btm-sml"
                  >
                    {t("FEEDBACK.FORM.LAST_NAME")}
                  </InputLabel>
                  <CustomTextField
                    title={"FEEDBACK.FORM.LAST_NAME"}
                    field={"last_name"}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row margin margin-btm-m">
                <InputLabel htmlFor="email" className="align-left margin-btm-sml">
                  <DraftsIcon /> {t("FEEDBACK.FORM.EMAIL")}
                </InputLabel>
                <CustomTextField
                  title={"FEEDBACK.FORM.EMAIL_LABEL"}
                  field={"email"}
                  onChange={handleInputChange}
                />
              </div>
              <div className="feedback-submit-button">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("FEEDBACK.FORM.SEND_BUTTON")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
interface CustomTextFieldProps {
  title: string;
  field: string;
  onChange: any;
}

const CustomTextField = (props: CustomTextFieldProps) => {
  const { t } = useTranslation();
  const { title, field, onChange } = props;
  return (
    <TextField
      size="small"
      label={t(title)}
      id={field}
      name={field}
      onChange={onChange}
    />
  );
};