import ApiService, { ApiResponse } from "./ApiService";
import { ConnectionResponseModel } from "../models/ConnectionResponseModel";
class ConnectionService extends ApiService {
  constructor() {
    super("connection");
  }
  public async getConnections(): Promise<
    ApiResponse<ConnectionResponseModel[]>
  > {
    return this.get<ConnectionResponseModel[]>("");
  }
  public async requestMatch(){
    return this.get("/request")
  }
}
export default ConnectionService;
