export enum BuddyOnboardingStepsEnum{
    Start = 0,
    Personal = 1,
    SubPersonalFirst = 2,
    SubPersonalSecond = 3,
    Education = 4,
    SubEducationFirst = 5,
    Motivation = 6,
    ProfilePicture = 7,
    Interests = 8,
    SubInterestsFirst = 9,
    BuddyHelp = 10,
    None = 11
}

export enum StudentOnboardingStepsEnum{
    Start = 0,
    Personal = 1,
    SubPersonalFirst = 2,
    SubPersonalSecond = 3,
    Education = 4,
    SubEducationFirst = 5,
    ProfilePicture = 6,
    Interests = 7,
    SubInterestsFirst = 8,
    BuddyHelp = 9,
    None = 11
}