import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Option {
    id: string;
    name: string;
}

interface SelectSmallProps {
    data: Option[];
    valuee: string | null;
    onChange: (value: string) => void;
    setValue: (valuee: string) => void;
    setTempState: (temp: string) => void;
    title: string | null;
}

export default function SelectSmall({ data, onChange, setValue, valuee, setTempState, title }: SelectSmallProps) {

    const handleChange = (event: SelectChangeEvent) => {
        setTempState(event.target.value)

        onChange(event.target.value);
        // setValue(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
            <InputLabel id="demo-select-small-label">{title}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={valuee ?? ""}
                label="Options"
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {data.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
