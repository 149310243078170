import StudentRequest from "../StudentRequest/StudentRequest";
import BuddyRequest from "../BuddyRequest/BuddyRequest";
import Matchmaking from "../../pages/Matchmaking/Matchmaking";
import StudentList from "../Student/StudentList";
import BuddyList from "../Buddy/BuddyList";
import Dashboard from "../Dashboard/Dashboard";
import University from "../University/University";
import Semesters from "../Semesters/Semesters";
import Matches from "../Matches/Matches";

const universityRoutes = [
  {
    path: "/student-requests",
    element: <StudentRequest />,
  },
  {
    path: "/buddy-requests",
    element: <BuddyRequest />,
  },
  {
    path: "/matchmaking",
    element: <Matchmaking />,
  },
  {
    path: "/students",
    element: <StudentList />,
  },
  {
    path: "/buddies",
    element: <BuddyList />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/university",
    element: <University />,
  },
  {
    path: "/semesters",
    element: <Semesters />,
  },
  {
    path: "/matches",
    element: <Matches />,
  },
];

export default universityRoutes;
