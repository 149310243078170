import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LookupTypeEnum } from "../../enums/lookupType-enum";
import { LookupVM } from "../../models/LookupVM";
import { SemesterItemVM, SemesterVM } from "../../models/SemesterVM";
import { localStorageService } from "../../services/localStorageService";
import OnboardingService from "../../services/onboardingService";
import LookupService from "../../services/lookupService";
import SemesterService from "../../services/semesterService";
import OnboardingVM from "../../models/OnboardingVM";
import OnboardingLookupVM from "../../models/OnboardingLookupVM";
import UserResponseModel from "../../models/UserReponseModel";
import UserService from "../../services/UserService";
import "./OnboardingEducation.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#33CC99", //this overide blue color with base green color of our app
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat Regular",
    fontSize: 11,
  },
});

interface Props {
  setDisableContinueButton: Dispatch<SetStateAction<boolean>>;
  onboardingModel?: OnboardingVM;
  setOnboardingModel: Dispatch<SetStateAction<OnboardingVM | undefined>>;
}

const OnboardingEducation: React.FC<Props> = ({
  setDisableContinueButton,
  onboardingModel,
  setOnboardingModel,
}) => {
  const [studyId, setStudyId] = useState("");
  const [studies, setStudies] = useState([] as LookupVM[]);
  const [semesterId, setSemesterId] = useState("");
  const [semesters, setSemesters] = useState<SemesterItemVM[]>([] as SemesterItemVM[]);
  const [localOnboardingModel] = useState(onboardingModel);
  const { t } = useTranslation("");
  const handleChangeStudyId = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setStudyId(value);
  };
  const handleChangeSemesterId = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setSemesterId(value);
  };
  const isValid = () => {
    return studyId !== "" && semesterId !== "";
  };
  const updateOnboardingModel = () => {
    if (localOnboardingModel) {
      if (studyId) {
        const departmentLookup: OnboardingLookupVM = { lookup_id: studyId };
        localOnboardingModel.department = departmentLookup;
      }
      if (semesterId) localOnboardingModel.semester_id = semesterId;
      setOnboardingModel(localOnboardingModel);
    }
  };

  async function setSemestersState() {
    const semestersKey = "semesters";
    const userInfoKey = "user_info";
    const cachedDataSemesters = localStorageService.getItem(semestersKey);
    if (cachedDataSemesters) {
      let semesterItems = (JSON.parse(cachedDataSemesters) as SemesterVM).semesters;
      setSemesters(semesterItems);
    } else {
      const cachedDataUserInfo = localStorageService.getItem(userInfoKey);
      let userData;
      if (cachedDataUserInfo) {
        userData = JSON.parse(cachedDataUserInfo) as UserResponseModel;
      } else {
        const response = await new UserService().getUser();
        userData = response.data;
        localStorageService.setItem(userInfoKey, JSON.stringify(userData));
      }
      const universityId = userData.university_id;
      const response = await new SemesterService().getSemesters(universityId);
      const data = response.data;
      localStorageService.setItem(semestersKey, JSON.stringify(data));
      setSemesters(data.semesters);
    }
  }

  async function setStudiesState() {
    const cachedData = localStorageService.getItem("studies");
    if (cachedData) {
      setStudies(JSON.parse(cachedData));
    } else {
      const response = await new LookupService().getByLookupType(
        LookupTypeEnum.Departments
      );
      const data = response.data;
      let validData = data.filter(x => !x.name.includes('xxx'));
      localStorageService.setItem("studies", JSON.stringify(validData));
      setStudies(validData);
    }
  }

  useEffect(() => {
    new OnboardingService().handleContinueButtonChange(
      isValid,
      setDisableContinueButton
    );
    updateOnboardingModel();
  }, [studyId, semesterId]);

  useEffect(() => {
    setSemestersState();
    setStudiesState();
    if (localOnboardingModel?.semester_id)
      setSemesterId(localOnboardingModel!.semester_id)
    if (localOnboardingModel?.department)
      setStudyId(localOnboardingModel.department.lookup_id)
  }, [])

  useEffect(() => {
    if (studies.length > 0)
      setStudyId(studies[0].id)//set Economics as default, NOT PERMANENT SOLUTION
  }, [studies])

  return (
    <div>
      <div className="education-wrapper">
        <div className="choose-primary-education-wrapper">
          <div className="choose-study">
            <p className="bold-font m-0">{t("EDUCATION.STUDY")}</p>
            <Container sx={{ width: "250px" }} component="main" maxWidth="xs">
              <ThemeProvider theme={theme}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Department"
                    name="studyId"
                    value={studyId}
                    onChange={handleChangeStudyId}
                  >
                    {studies.map((element) => {
                      return (
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Container>
          </div>
          <div className="choose-study-second">
            <p className="bold-font m-0">{t("EDUCATION.SEMESTER")}</p>
            <Container sx={{ width: "250px" }} component="main" maxWidth="xs">
              <ThemeProvider theme={theme}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Semester
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Semester"
                    name="semesterId"
                    value={semesterId}
                    onChange={handleChangeSemesterId}
                  >
                    {semesters?.map((element) => {
                      return (
                        <MenuItem key={element.id} value={element.id}>
                          {element.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingEducation;
