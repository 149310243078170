import { useEffect, useState } from "react";
import styled from "styled-components";
import { ConnectionResponseModel } from "../../models/ConnectionResponseModel";
import ConnectionService from "../../services/ConnectionService";
import { map } from "lodash";
import MatchItem from "./MatchItem";
import UserResponseModel from "../../models/UserReponseModel";

const Wrapper = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: scroll;
  height: 100%;
`;

interface GroupItem {
  buddies: UserResponseModel[];
  students: UserResponseModel[];
  number: number;
}
export default function Matches() {
  const [groupItems, setGroupItems] = useState<GroupItem[]>([]);
  const fetchConnections = async () => {
    const response = await new ConnectionService().getConnections();
    if (response.message === "OK") {
      const data = mapConnections(response.data);
      setGroupItems(data);
    }
  };

  const mapConnections = (connections: ConnectionResponseModel[]) => {
    const groupData: any = [];
    map(connections, (item) => {
      const { buddies, students, number } = item;
        groupData.push({
          buddies: buddies,
          students: students,
          number: number
        });
      }
    );
    return groupData;
  };

  useEffect(() => {
    fetchConnections().then();
  }, []);
  return (
    <Wrapper>
      {map(groupItems, (item) => {
        return (
          <MatchItem
            buddies={item.buddies}
            students={item.students}
            key={item.number}
          />
        );
      })}
    </Wrapper>
  );
}
