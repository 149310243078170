import BaseTable from "../BaseTable/BaseTable";
import "../StudentRequest/StudentRequest.css";
import { useTranslation } from "react-i18next";
import SearchInput from "../SearchInput/SearchInput";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import UserSearchRequestModel from "../../models/UserSearchRequestModel";
import UserResponseModel from "../../models/UserReponseModel";
import VisibilityIcon from '@mui/icons-material/Visibility';
import useAlert from "../Alert/useAlert";
import { Avatar } from "@mui/material";
import IsEmpty, { map } from "lodash";
import Modal from "../Modals/BaseModal";
import ViewModal from "../Modals/ViewModal";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import UserDetails from "../../models/UserDetails";

export default function BuddyList() {
  const { showAlert, AlertComponent } = useAlert();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UserResponseModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [searchText, setSearch] = useState<string>("");

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      flex: 0.2,
      renderCell: (params) => (
        <Avatar
          alt="User Avatar"
          src={
            !IsEmpty(params.value)
              ? params.value
              : "https://www.citypng.com/public/uploads/preview/download-black-male-user-profile-icon-png-116371332534k5baafcll.png?v=2023051918"
          }
          style={{ width: 40, height: 40 }}
        />
      ),
    },
    {
      field: "full_name",
      headerName: "Name",
      flex: 0.9,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.9,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.9,
      type: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<VisibilityIcon color="warning" />}
          label="Approve"
          onClick={() => { handleUser(params) }}
        />,
      ]
    }
  ];

  const handleUser = (params: any) => {
    setSelectedUser(params.row)
    setOpenModal(true);
  }

  const handleSearch = (event: any) => {
    setSearch(event.target.value);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const request: UserSearchRequestModel = {
        role: 0,
        skip:
          paginationModel.page > 0
            ? paginationModel.page * paginationModel.pageSize
            : 0,
        take: paginationModel.pageSize,
        search: searchText,
        status: 1,
        connection_status: 1,
        buddy_status: 1,
        student_status: 0
      };

      const response = await new UserService().getUsersPerUniversity(request);
      if (response.message === "OK") {
        mapData(response.data.users);
        setRowData(response.data.users);
        setRowCount(response.data.total_count);
      }
    } catch (error) {
      showAlert(t("BUDDY_LIST.USER_FETCH_ERROR"), "error");
    } finally {
      setIsLoading(false);
    }
  }, [searchText, paginationModel.page, paginationModel.pageSize]);

  useEffect(() => {
    fetchUsers().then();
  }, [paginationModel, searchText, fetchUsers]);

  const mapData = (list: UserResponseModel[]) => {
    return map(list, (item) => {
      return (item.full_name = `${item.first_name} ${item.last_name}`);
    });
  };

  return (
    <div className="student-request-container">
      <ViewModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        modalTitle=""
        modalDescription=""
        user={selectedUser}
      />
      {AlertComponent}
      <div className="student-request-title">
        {/* <h2>{t("BUDDY_LIST.TITLE")}</h2> */}
      </div>
      <div className="klasa">
        <div className="student-request-search">
          <SearchInput
            handleSearch={handleSearch}
            placeholder={t("BUDDY_LIST.SEARCH_PLACEHOLDER")}
          />
        </div>
        <div className="student-request-table">
          <BaseTable
            columns={columns}
            rows={rowData}
            totalRowCount={rowCount}
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
            loading={isLoading}
          />
          <Modal />
        </div>
      </div>
    </div>
  );
}
