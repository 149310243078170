import { useNavigate } from "react-router-dom";
import { UserTypeEnum } from "../../../enums/userType-enum";
import "./PreRegistrationCard.css";

interface Props {
  text: string;
}

const PreRegistrationCard: React.FC<Props> = ({ text }) => {
  const navigate = useNavigate();
  const navigateTo = (text: string) => {
    if (text.includes("Student"))
      navigate("/register", {state: {userType: UserTypeEnum.Student}});
    else if(text.includes("Buddy")){
      navigate("/register", {state: {userType: UserTypeEnum.Buddy}});
    }
    else navigate("/contact-us");
  };

  return (
    <div
      className="pre-registration-card-wrapper"
      onClick={() => navigateTo(text)}
    >
      <div className="icon-text-container">
        <div className="card-icon">
          {(() => {
            if (text.includes("Student"))
              return (
                <img
                  src={require("../../../assets/icons/student-icon.png")}
                  alt="image"
                />
              );
            else if (text.includes("Buddy"))
              return (
                <img
                  src={require("../../../assets/icons/handshake-icon.png")}
                  alt="image"
                />
              );
            else
              return (
                <img
                  src={require("../../../assets/icons/university-icon.png")}
                  alt="image"
                />
              );
          })()}
        </div>
        <p className="semiBold-font card-text">{text}</p>
      </div>
      <div className="arrow-icon">
        <img src={require("../../../assets/icons/right-arrow-icon.png")} />
      </div>
    </div>
  );
};

export default PreRegistrationCard;
