export enum NavbarEnum {
  Landing = 1,
  PreRegistration = 2,
  Login = 3,
  Feed = 4,
  StudentOnboarding = 5,
  UserProfile = 6,
  AboutLoggedIn = 7,
  University = 8,
  StudentBuddyMatchProfile = 9
}
